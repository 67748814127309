import React, { useContext, useEffect, useState } from "react"
import { useT } from "../../common/i18n"
import Page from "../../common/ui/page"
import { Link } from "react-router-dom"
import { Alert, Button, Divider, Empty, Flex, Popconfirm, Popover, Space, Spin, Timeline, Typography } from "antd"
import {
    ClockCircleOutlined,
    CloseCircleTwoTone,
    CopyOutlined,
    EditOutlined,
    LoadingOutlined,
    NotificationOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    WarningOutlined,
    WarningTwoTone,
} from "@ant-design/icons"
import { BannerContext, QUERY_NOTIFICATION } from "../../common/context/bannerContextProvider"
import { blue, green, orange, red } from "./colors"
import gql from "graphql-tag"
import { Log } from "../../common/log"
import { HasPermission } from "../../common/ui/permissions"
import { useMutation, useQuery } from "@apollo/client"
import NewAnnouncementForm from "./newAnnouncementForm"

import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import localizedFormat from "dayjs/plugin/localizedFormat"
import i18next from "i18next"
import remarkGfm from "remark-gfm"
import Markdown from "react-markdown"
import { EditAnnouncementItemContext, EditAnnouncementItemProvider } from "./editAnnouncementContextProvider"

dayjs.extend(isBetween)
dayjs.extend(localizedFormat)

const { Text } = Typography

export const QUERY_FUTURE_NOTIFICATIONS = gql`
    query getFutureNotifications {
        getFutureNotifications {
            id
            title
            message
            type
            level
            activeFrom
            activeUntil
        }
    }
`

export const QUERY_PAST_NOTIFICATIONS = gql`
    query getPastNotifications {
        getPastNotifications {
            id
            title
            message
            type
            level
            activeFrom
            activeUntil
        }
    }
`

export const MUTATION_DELETE_NOTIFICATION = gql`
    mutation deleteNotification($id: ID!) {
        deleteNotification(id: $id)
    }
`

function AnnouncementEntry({ notification, overlap, allowDelete, isArchive }) {
    const t = useT()
    const { setEditAnnouncement, setIsCopy } = useContext(EditAnnouncementItemContext)

    const [deleteNotification] = useMutation(MUTATION_DELETE_NOTIFICATION)

    const [failedToDelete, setFailedToDelete] = useState(false)

    const confirmDelete = () => {
        deleteNotification({
            variables: { id: notification.id },
            refetchQueries: [{ query: QUERY_FUTURE_NOTIFICATIONS }, { query: QUERY_NOTIFICATION }],
        }).catch((error) => {
            Log.Error("AnnouncementEntry.confirmDelete", "error", error)
            setFailedToDelete(true)
        })
    }
    const confirmEdit = () => {
        setEditAnnouncement({ ...notification })
        setIsCopy(false)
        Log.Debug("AnnouncementEntry.confirmEdit", "notification", notification)
    }

    const confirmCopy = () => {
        setEditAnnouncement({ ...notification })
        setIsCopy(true)
        Log.Debug("AnnouncementEntry.confirmCopy", "notification", notification)
    }

    return (
        <>
            {dayjs(notification?.activeUntil).format("llll")}
            &nbsp;
            <Popconfirm
                icon={<CopyOutlined style={{ color: blue }} />}
                title={t("announcement.tooltip.copy.title", "Copy announcement")}
                description={<Text>{t("announcement.tooltip.copy.description", "Do you want to copy this announcement?")}</Text>}
                onConfirm={confirmCopy}
                okText={t("announcement.tooltip.copy-button", "Copy")}
                cancelText={t("announcement.tooltip.cancel-button", "Cancel")}>
                <CopyOutlined style={{ color: blue }} />
            </Popconfirm>
            &nbsp;
            { !isArchive &&
                <Popconfirm
                    icon={<EditOutlined style={{ color: blue }} />}
                    title={t("announcement.tooltip.edit.title", "Edit announcement")}
                    description={<Text>{t("announcement.tooltip.edit.description", "Do you want to edit this announcement?")}</Text>}
                    onConfirm={confirmEdit}
                    okText={t("announcement.tooltip.edit-button", "Edit")}
                    cancelText={t("announcement.tooltip.cancel-button", "Cancel")}>
                    <EditOutlined style={{ color: blue }} />
                </Popconfirm>
            }
            {overlap && (
                <>
                    &nbsp;
                    <Popconfirm
                        icon={<WarningTwoTone twoToneColor={orange} />}
                        title={t("announcement.tooltip.overlap.title", "Overlap with another announcement!")}
                        description={
                            <Text>
                                {t("announcement.tooltip.overlap.info1", "This announcement overlaps with a previous one.")}
                                <br />
                                {t("announcement.tooltip.overlap.info2", "This announcement will not be displayed while the other announcement is active.")}
                                <br />
                                {t("announcement.tooltip.overlap.info3", "Do you want to edit this announcement?")}
                            </Text>
                        }
                        onConfirm={confirmEdit}
                        okText={t("announcement.tooltip.edit-button", "Edit")}
                        cancelText={t("announcement.tooltip.cancel-button", "Cancel")}>
                        <WarningOutlined style={{ color: orange }} />
                    </Popconfirm>
                </>
            )}
            &nbsp;
            {allowDelete && (
                <Popconfirm
                    icon={<QuestionCircleOutlined style={{ color: red }} />}
                    title={t("announcement.tooltip.delete.title", "Delete announcement?")}
                    description={t("announcement.tooltip.delete.info", "Do you want to delete this announcement?")}
                    onConfirm={confirmDelete}
                    okText={t("announcement.tooltip.delete-button", "Delete")}
                    cancelText={t("announcement.tooltip.cancel-button", "Cancel")}>
                    <CloseCircleTwoTone twoToneColor={red} />
                </Popconfirm>
            )}
            {failedToDelete && (
                <>
                    &nbsp;
                    <Text style={{ color: red }}>{t("announcement.tooltip.delete.fail", "Failed to delete")}</Text>
                </>
            )}
            <br />
            {notification?.title && (
                <Alert
                    message={<Markdown remarkPlugins={[remarkGfm]}>{notification?.title}</Markdown>}
                    description={<Markdown remarkPlugins={[remarkGfm]}>{notification?.message}</Markdown>}
                    type={notification.level}
                    showIcon
                />
            )}
            {!notification?.title && (
                <Alert message={<Markdown remarkPlugins={[remarkGfm]}>{notification?.message}</Markdown>} type={notification.level} showIcon />
            )}
        </>
    )
}

const AnnouncementPageImpl = () => {
    const t = useT()
    const bannerContext = useContext(BannerContext)
    const editAnnouncementContext = useContext(EditAnnouncementItemContext)

    const futureNotificationsResult = useQuery(QUERY_FUTURE_NOTIFICATIONS, {
        fetchPolicy: "network-only",
    })
    const pastNotificationsResult = useQuery(QUERY_PAST_NOTIFICATIONS, {
        fetchPolicy: "network-only",
    })

    const [isEditing, setIsEditing] = useState(false)
    const [showHistory, setShowHistory] = useState(false)

    const [, setRerender] = useState(false)

    useEffect(() => {
        // rerender if t changes
        setRerender((previous) => !previous)
        Log.Debug("AnnouncementPage.useEffect", "dayjs.locale", dayjs.locale())
    }, [i18next.language])

    useEffect(() => {
        Log.Debug("AnnouncementPage.useEffect", "editAnnouncementContext.editAnnouncement", editAnnouncementContext.editAnnouncement)
        if (editAnnouncementContext.editAnnouncement) {
            setIsEditing(true)
        }
    }, [editAnnouncementContext.editAnnouncement])

    const mapNotificationToItem = (notification, allowDelete, color, icon, description, isArchive, idx, arr) => {
        let displayColor = color
        if (!displayColor) {
            switch (notification.level) {
                case "info":
                    displayColor = blue
                    break
                case "success":
                    displayColor = green
                    break
                case "warning":
                    displayColor = orange
                    break
                case "error":
                    displayColor = red
                    break
                default:
                    displayColor = blue
            }
        }

        // compare idx to all previous notifications to check for overlap
        let isOverlap = false
        if (idx > 0) {
            for (let i = 0; i < idx; i++) {
                let previous = arr[i]
                isOverlap =
                    dayjs(notification.activeFrom).isBetween(dayjs(previous.activeFrom), dayjs(previous.activeUntil), "[]") ||
                    dayjs(notification.activeUntil).isBetween(dayjs(previous.activeFrom), dayjs(previous.activeUntil), "[]") ||
                    dayjs(previous.activeFrom).isBetween(dayjs(notification.activeFrom), dayjs(notification.activeUntil), "[]") ||
                    dayjs(previous.activeUntil).isBetween(dayjs(notification.activeFrom), dayjs(notification.activeUntil), "[]") ||
                    dayjs(previous.activeFrom).isSame(dayjs(notification.activeFrom)) ||
                    dayjs(previous.activeUntil).isSame(dayjs(notification.activeUntil))
                if (isOverlap) {
                    break
                }
            }
        }

        return {
            label: <>{dayjs(notification.activeFrom).format("llll")}</>,
            children: <AnnouncementEntry notification={notification} overlap={isOverlap} allowDelete={allowDelete} isArchive={isArchive} />,
            color: displayColor,
            dot: icon && (
                <Popover title={description} trigger="hover">
                    {icon}
                </Popover>
            ),
        }
    }

    const sortNotifications = (a, b) => {
        // Log.Debug("NotificationPage.sortNotifications", "a", a, "b", b)
        if (dayjs(a?.activeFrom).isBefore(dayjs(b?.activeFrom))) {
            // Log.Debug("NotificationPage.sortNotifications", "a is before b")
            return -1
        }
        if (dayjs(a?.activeFrom).isAfter(dayjs(b?.activeFrom))) {
            // Log.Debug("NotificationPage.sortNotifications", "a is after b")
            return 1
        }
        if (dayjs(a?.activeFrom).isSame(dayjs(b?.activeFrom))) {
            // fallback to activeUntil
            if (dayjs(a?.activeUntil).isBefore(dayjs(b?.activeUntil))) {
                return -1
            }
            if (dayjs(a?.activeUntil).isAfter(dayjs(b?.activeUntil))) {
                return 1
            }
            if (dayjs(a?.activeUntil).isSame(dayjs(b?.activeUntil))) {
                return 0
            }
        }
    }

    // active items - should be sorted already
    let activeItems = Array.from(bannerContext?.notifications || [])?.map((notification, idx, arr) => {
        return mapNotificationToItem(
            notification,
            true,
            null,
            <NotificationOutlined />,
            t("announcement.description.active", "Active announcement"),
            false,
            idx,
            arr
        )
    })

    //future items
    let futureItems = Array.from(futureNotificationsResult?.data?.getFutureNotifications || [])
        ?.sort(sortNotifications)

        .map((notification, idx, arr) => {
            return mapNotificationToItem(
                notification,
                true,
                null,
                <ClockCircleOutlined />,
                t("announcement.description.scheduled", "Scheduled announcement"),
                false,
                idx,
                arr
            )
        })

    let historyItems = Array.from(pastNotificationsResult?.data?.getPastNotifications || [])
        ?.sort(sortNotifications)
        .map((notification) => {
            return mapNotificationToItem(notification, false, "grey", null, null, true)
        })

    return (
        <HasPermission role={["admin"]}>
            <Page
                trail={[
                    <Link key={1} to={"."}>
                        {t("configuration.nav.announcement", "Announcements")}
                    </Link>,
                ]}
                title={t("announcement.title", "Announcements Manager")}
                withPadding={true}>
                <Divider dashed={true}>
                    <Button type="primary" size="middle" icon={<PlusOutlined />} onClick={() => setIsEditing(!isEditing)} disabled={isEditing}>
                        {t("announcement.schedule-button", "Schedule Announcement")}
                    </Button>
                </Divider>

                <Flex justify={activeItems?.length === 0 && futureItems.length === 0 ? "space-around" : "flex-start"} align="center">
                    {isEditing && (
                        <NewAnnouncementForm
                            closeCallback={() => {
                                setIsEditing(false)
                            }}
                        />
                    )}
                    {activeItems?.length === 0 && futureItems.length === 0 && (
                        <>
                            {isEditing && (
                                <Space direction="vertical" size="middle" align="center">
                                    <Spin size="large" indicator={<LoadingOutlined spin />} />
                                    <Text>{t("announcement.editing", "Editing...")}</Text>
                                </Space>
                            )}

                            {!isEditing && (
                                <Spin tip={t("announcement.loading", "Loading...")} spinning={futureNotificationsResult.loading}>
                                    <Empty
                                        style={{ marginTop: "20px", marginBottom: "20px" }}
                                        description={<Text>{t("announcement.no-data", "You have no announcements scheduled")}</Text>}></Empty>
                                </Spin>
                            )}
                        </>
                    )}
                    {(activeItems?.length > 0 || futureItems.length > 0) && (
                        <Timeline
                            style={{
                                padding: "20px",
                                maxHeight: "450px",
                                overflowX: "hidden",
                                overflowY: "scroll",
                                width: isEditing ? "50%" : "70%",
                            }}
                            mode="left"
                            pending={isEditing && t("announcement.editing", "Editing...")}
                            reverse={true}
                            items={activeItems.concat(futureItems)}
                        />
                    )}
                </Flex>
                <Divider style={{ marginTop: "40px" }} dashed={true}>
                    <Button type="primary" size="middle" icon={<PlusOutlined />} onClick={() => setShowHistory(!showHistory)}>
                        {t("announcement.history-button", "History")}
                    </Button>
                </Divider>
                {showHistory && historyItems?.length > 0 && (
                    <Flex justify="flex-start" align="center">
                        <Timeline
                            style={{
                                padding: "20px",
                                maxHeight: "450px",
                                overflowX: "hidden",
                                overflowY: "scroll",
                                width: "70%",
                            }}
                            mode="left"
                            reverse={true}
                            items={historyItems}
                        />
                    </Flex>
                )}
                {showHistory && historyItems?.length === 0 && (
                    <Flex align="center" justify="center">
                        <Spin tip={t("announcement.loading", "Loading...")} spinning={pastNotificationsResult.loading}>
                            <Empty
                                style={{ marginTop: "20px", marginBottom: "20px" }}
                                description={<Text>{t("announcement.no-history", "You have no archived announcements")}</Text>}></Empty>
                        </Spin>
                    </Flex>
                )}
            </Page>
        </HasPermission>
    )
}

export const AnnouncementPage = () => {
    return (
        <EditAnnouncementItemProvider>
            <AnnouncementPageImpl />
        </EditAnnouncementItemProvider>
    )
}
