import React, {useContext, useRef, useState} from "react";
import Page from "../../../common/ui/page"
import {Link} from "react-router-dom"
import {useMutation, useQuery} from "@apollo/client"
import {useAuthContext} from "../../../common/context/authContext"
import {useT} from "../../../common/i18n"
import {useGraphqlLoadingComponent} from "../../../common/graphql"
import {Button, Flex, Form, Input, InputRef, Popconfirm, Space, Table, TablePaginationConfig} from "antd"
import {CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, SearchOutlined} from "@ant-design/icons"
import Highlighter from "react-highlight-words"
import {ColumnType} from "antd/es/table"
import EditableCell from "./EditableCell"
import {FilterConfirmProps, FilterValue, SorterResult} from "antd/es/table/interface"
import AddKeyModal from "./AddKeyModal"
import {
    CREATE_WMBUS_KEY,
    DELETE_WMBUS_KEY,
    GET_ORGANISATION,
    UPDATE_WMBUS_KEY,
    WMBUS_KEYS_WITH_PLAIN_TEXT
} from "./wmbusqueries"
import {validateManufacturer, validateMeterId, validateMeterKey} from "./wmbusValidators"
import {Log} from "../../../common/log";
import {blueButtonCSS, disabledGreyButtonCSS} from "../../../common/ui/cssValues";
import CollapsibleString from "./CollapsibleString";
import {FeatureContext} from "../../../common/context/featureContext";
import {FeatureNotEnabled} from "../../../common/featureNotEnabled";


export interface WmBusKeyItem {
    id: string
    meterId: string
    manufacturer: string
    encryptionKeyMasked: string
    encryptionKeyPlainText: string
    encryptionKeyHash: string
}

type DataIndex = keyof WmBusKeyItem | "";

interface TableParams {
    sort?: SorterResult<WmBusKeyItem>
    searchParams: Map<DataIndex, string | null>
    page: number
    pageSize: number
}

interface FilterInputType {
    field: string
    op: string
    value: string
}

export default function WmbusConfigPage() {
    const t = useT();
    const [form] = Form.useForm();

    const auth = useAuthContext();
    const [editingId, setEditingId] = useState('');
    const [tableParams, setTableParams] = useState<TableParams>({
        searchParams : new Map<DataIndex, string | null>(),
        page: 1,
        pageSize: 100
    });
    const [isAddKeyOpen, setIsAddKeyOpen] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const searchInput = useRef<InputRef>(null);

    let filter : FilterInputType[] = [];
    for (const [key, value] of tableParams.searchParams) {
        if (value === null) {
            filter = filter.concat([{ field: key, op: "eq", value: "" }])
        } else if (value !== "") {
            filter = filter.concat([{ field: key, op: "ilike", value: "%" + value + "%" }])
        }
    }
    Log.Debug("WmbusConfigPage - filter", filter)

    const orgResult = useQuery(GET_ORGANISATION, {
        variables: {
            id: auth.organisationId()
        }
    })


    const wmbusKeysResult = useQuery(WMBUS_KEYS_WITH_PLAIN_TEXT, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
        variables: {
            orgId: auth.organisationId(),
            page: {
                offset: tableParams.pageSize * (tableParams.page - 1),
                limit: tableParams.pageSize,
            },
            sort: {
                field: tableParams.sort?.field || "id",
                direction: tableParams.sort?.order === "ascend" ? "ASC" : "DESC"
            },
            filter: filter
        }
    });

    const [updateWmbusKey] = useMutation(UPDATE_WMBUS_KEY, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
    });

    const [createWmbusKey] = useMutation(CREATE_WMBUS_KEY, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
    });

    const [deleteWmbusKey] = useMutation(DELETE_WMBUS_KEY, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
    });

    // TODO: Ensure all hooks are executed before rendering license failure fallback?
    const license = useContext(FeatureContext) as {result: unknown, validateFeatures: (feature: string) => boolean}
    if (!license.validateFeatures("wmbus-api")) {
        return <Page
            trail={[
                <Link to={window.location.href} key={1}>
                    {t("org.config.nav.wmbus.trail", "wMbus Keys")}
                </Link>,
            ]}
            title={t("org.config.wmbus.title", "wMbus Keys")}>
            <FeatureNotEnabled/>
        </Page>
    }

    const edit = (record: WmBusKeyItem) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingId(record.id);
    };

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
        Log.Debug("WmbusConfigPage - handleSearch", selectedKeys, dataIndex)
        const newSearchText = selectedKeys[0] === "" || selectedKeys[0] === undefined ? null : selectedKeys[0]
        confirm()

        tableParams.searchParams.set(dataIndex, newSearchText)

        setTableParams({ ...tableParams })
    }

    const handleReset = (clearFilters: () => void, dataIndex: DataIndex) => {
        clearFilters()
        tableParams.searchParams.delete(dataIndex)
        setTableParams({ ...tableParams })
    }

    const handleResetAll = (clearFilters: () => void) => {
        clearFilters()
        tableParams.searchParams = new Map<DataIndex, string | null>()
        setTableParams({ ...tableParams })
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<WmBusKeyItem> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Flex vertical gap="small" style={{ width: "100%" }}>
                    <Space direction="vertical">
                        <Button onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)} size="large" type="primary" block>
                            {t("common.button.search", "Search")}
                        </Button>
                    </Space>
                    <Space.Compact direction="vertical" style={{ color: "blue" }} block>
                        <Button onClick={() => clearFilters && handleReset(clearFilters,  dataIndex)} size="middle" block>
                            {t("org.config.wmbus.reset", "Reset")}
                        </Button>

                        <Button danger onClick={() => clearFilters && handleResetAll(clearFilters)} size="middle" type="dashed" block>
                            {t("org.config.wmbus.reset_all", "Reset All")}
                        </Button>
                    </Space.Compact>
                </Flex>
            </div>
        ),
        filterIcon: () => (
            Log.Debug(
                "WmbusConfigPage - filterIcon blue",
                tableParams.searchParams.has(dataIndex),
                "dataIndex",
                dataIndex,
                "searchParams",
                tableParams.searchParams
            ),
            <SearchOutlined style={{ color: tableParams.searchParams.has(dataIndex) ? "#1890ff" : undefined }} />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text) =>
            tableParams.searchParams.get(dataIndex) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[tableParams.searchParams.get(dataIndex)]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    })

    const cancelEditing = () => {
        setEditingId('');
    };

    const pageChange = (page: number, pageSize: number) => {
        cancelEditing()
        setTableParams({
                ...tableParams,
                page: page,
                pageSize: pageSize
            }
        )
        return
    }

    const isEditing = (record: WmBusKeyItem) => record.id === editingId;


    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<WmBusKeyItem> | SorterResult<WmBusKeyItem>[],
    ) => {
        setTableParams((tableParams) => {
            return {
                ...tableParams,
                sort: sorter as SorterResult<WmBusKeyItem>,
                page: pagination.current || tableParams.page,
                pageSize: pagination.pageSize || tableParams.pageSize
            }
        });
    };

    function sortOrderForField(field: string) {
        if (tableParams.sort?.field === field) {
            return tableParams.sort?.order
        } else {
            return undefined
        }
    }



    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore wait for ts migration
    let loading = useGraphqlLoadingComponent(orgResult);
    if (loading) {
        return loading;
    }

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore wait for ts migration
    loading = useGraphqlLoadingComponent(wmbusKeysResult);
    if (loading) {
        return loading;
    }


    const displayKeyInPlainText = orgResult.data.getOrganisation.allowWmbusKeysInPlainText && auth.hasRole("admin", "org-admin")
    Log.Debug("displayKeyInPlainText", displayKeyInPlainText)
    const save = async (oldKey: WmBusKeyItem) => {
        const row = (await form.validateFields()) as WmBusKeyItem;
        Log.Debug("save:", row)
        let wmbusInput

        if (displayKeyInPlainText && row.encryptionKeyPlainText !== oldKey.encryptionKeyPlainText) {
            wmbusInput = {
                meterId: row.meterId,
                manufacturer: row.manufacturer,
                encryptionKey: row.encryptionKeyPlainText,
            }
        } else if (!displayKeyInPlainText && row.encryptionKeyMasked !== oldKey.encryptionKeyMasked){
            wmbusInput = {
                meterId: row.meterId,
                manufacturer: row.manufacturer
            }
            //ignore if only encryptionKeyMasked has only been changend on the first two or last two digits
            if (row.encryptionKeyMasked.substring(2, 30) !== oldKey.encryptionKeyMasked.substring(2, 30)) {
                wmbusInput.encryptionKey = row.encryptionKeyMasked
            }
        }
        else {
            wmbusInput = {
                meterId: row.meterId,
                manufacturer: row.manufacturer,
            }
        }

        updateWmbusKey({
            variables: {
                id: oldKey.id,
                wmbusKey: wmbusInput
            }

        }).then(() => {
            setEditingId("");
            wmbusKeysResult.refetch()
        }).catch((err) => {
            Log.Error('update err:', err);
        })
    }


    const columns = [
        {
            title: t("org.config.wmbus.meter_id", "Meter Id"),
            dataIndex: "meterId",
            key: "meterId",
            editable: true,
            required: false,
            validationFunc: validateMeterId,
            sorter: true,
            sortOrder: sortOrderForField("meterId"),
            ...getColumnSearchProps("meterId"),
        },
        {
            title: t("org.config.wmbus.manufacturer", "Manufacturer"),
            dataIndex: "manufacturer",
            key: "manufacturer",
            editable: true,
            required: false,
            validationFunc: validateManufacturer,
            sorter: true,
            sortOrder: sortOrderForField("manufacturer"),
            ...getColumnSearchProps("manufacturer"),
        },
        {
            title: t("org.config.wmbus.encryption_key", "Encryption Key"),
            dataIndex: displayKeyInPlainText ? "encryptionKeyPlainText" : "encryptionKeyMasked",
            key: displayKeyInPlainText ? "encryptionKeyPlainText" : "encryptionKeyMasked",
            editable: true,
            required: true,
            validationFunc: validateMeterKey,
        },
        {
            title: t("org.config.wmbus.key_fingerprint", "Fingerprint"),
            dataIndex: "encryptionKeyHash",
            ...getColumnSearchProps("encryptionKeyHash"),
            render: (text: string) =>
                tableParams.searchParams.get("encryptionKeyHash") ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        searchWords={[tableParams.searchParams.get("encryptionKeyHash")]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ""}
                    />
                ) : (
                    <CollapsibleString fullStr={text} charLimit={5} withEnd={true} />
                ),
        },
        {
            title: "Operations",
            dataIndex: "operation",
            render: (_, record: WmBusKeyItem) => {
                const editable = isEditing(record)
                return editable ? (
                    <span>
                        <Space.Compact block>
                            <Button
                                icon={<CheckCircleOutlined style={{ color: "green" }} />}
                                style={{ color: "green", borderColor: "green" }}
                                onClick={() => save(record)}>
                                {t("common.button.save", "Save")}
                            </Button>
                            <Button danger icon={<CloseCircleOutlined />} onClick={cancelEditing}>
                                {t("common.button.cancel", "Cancel")}
                            </Button>
                        </Space.Compact>
                    </span>
                ) : (
                    <>
                        <Space.Compact block>
                            <Button
                                icon={<EditOutlined style={editingId ? disabledGreyButtonCSS : blueButtonCSS} />}
                                style={editingId ? disabledGreyButtonCSS : blueButtonCSS}
                                disabled={editingId !== ""}
                                onClick={() => edit(record)}>
                                {t("common.button.edit", "Edit")}
                            </Button>
                            <Popconfirm
                                title={t("org.config.wmbus.confirm-delete", "Delete wMbus Key?")}
                                onConfirm={() => {
                                    Log.Debug("delete: ", record.id)
                                    deleteWmbusKey({
                                        variables: {
                                            id: record.id,
                                        },
                                    })
                                        .then(() => {
                                            void wmbusKeysResult.refetch()
                                            Log.Debug("delete success:")
                                        })
                                        .catch((err) => {
                                            Log.Error("delete err:", err)
                                        })
                                }}>
                                <Button danger icon={<DeleteOutlined />} disabled={editingId !== ""}>
                                    {t("common.button.delete", "Delete")}
                                </Button>
                            </Popconfirm>
                        </Space.Compact>
                    </>
                )
            },
        },
    ]

    const wmbusKeys = wmbusKeysResult?.data?.getWmbusKeysForOrganisation?.result;
    const totalCount = wmbusKeysResult?.data?.getWmbusKeysForOrganisation?.totalCount;

    const showAddKeyModal = () => {
        setIsAddKeyOpen(true)
        return
    }

    const deleteMulti = () => {
        const promises = selectedRowKeys.map(key => {
            return deleteWmbusKey({
                variables: {
                    id: key
                }
            })
        })
        Promise.all(promises).then(() => {
            setSelectedRowKeys([])
            void wmbusKeysResult.refetch()
        })
    }

    const actions = (
        <div>
            <Space.Compact block>
                {selectedRowKeys.length > 0 && (
                    <Popconfirm title={t("org.config.wmbus.confirm-delete-selected", "Delete all Selected wMbus Keys?")} onConfirm={deleteMulti}>
                        <Button danger type="primary" size="middle">
                            <svg className={"slds-button__icon slds-m-right--x-small"} aria-hidden="true">
                                <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#delete`} />
                            </svg>
                            {t("org.config.wmbus.delete_selected_keys", "Delete selected Keys")}
                        </Button>
                    </Popconfirm>
                )}
                <Button size="middle" onClick={showAddKeyModal} style={{ ...blueButtonCSS, minWidth: 90 }}>
                    <svg className={"slds-button__icon slds-m-right--x-small"} aria-hidden="true">
                        <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#add`} />
                    </svg>
                    {t("org.config.wmbus.add_key", "Add Key")}
                </Button>
                <Link to={"import"}>
                    {auth.hasRole("admin", "org-admin") ? (
                        <Button size="middle" style={{ ...blueButtonCSS, minWidth: 90 }}>
                            <svg className={"slds-button__icon slds-m-right--x-small"} aria-hidden="true">
                                <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#upload`} />
                            </svg>
                            {t("common.button.import", "Import")}
                        </Button>
                    ) : null}
                </Link>
            </Space.Compact>
        </div>
    )

    const mergedColumns: ColumnType<WmBusKeyItem>[] = columns.map((col: Record<string, unknown>) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: WmBusKeyItem) => ({
                record,
                inputtype: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                required: col.required,
                validationFunc: col.validationFunc,
            }),
        };
    });


    const saveNewKey = (meterId: string, manufacturer: string, encryptionKey: string) => {
        void createWmbusKey(
            {
                variables: {
                    wmbusKey: {
                        meterId,
                        manufacturer,
                        encryptionKey,
                    }
                }
            }).then(() => wmbusKeysResult.refetch())
        Log.Debug(`Save Key ${meterId} ${manufacturer} ${encryptionKey}`)
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        Log.Debug('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Page
            trail={[
                <Link to={window.location.href} key={1}>
                    {t("org.config.nav.wmbus.trail", "wMbus Keys")}
                </Link>,
            ]}
            title={t("org.config.wmbus.title", "wMbus Keys")}
            info={<>{totalCount} {t("org.config.wmbus.keys","Keys", {count: totalCount})}</>}
            actions={actions}>
            <AddKeyModal isOpen={isAddKeyOpen} close={() => setIsAddKeyOpen(false)} onSave={saveNewKey} />

            <div className="slds-p-around--small">
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowSelection={rowSelection}
                        bordered
                        dataSource={wmbusKeys}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        rowKey={(record) => record.id}
                        pagination={{
                            onChange: pageChange,
                            total: totalCount,
                            current: tableParams.page,
                            pageSize: tableParams.pageSize,
                            position: ["bottomRight", "topRight"],
                        }}
                        onChange={handleTableChange}
                    />
                </Form>
            </div>
        </Page>
    )
}

