import {useState} from "react";
import { Log } from "../log";

// Helper to search in data tables
export function useMultiSelection() {
    const [selections, setSelections] = useState([]);

    return {
        setSelections: setSelections,
        selections: selections,
        handleSelectionEvent: (event, {selection}) => {
            Log.Debug("handleSelectionEvent", event, selection)
            setSelections(selection)
        }
    }
}