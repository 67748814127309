import React, {useContext} from "react";
import Page from "../../common/ui/page";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_DELETE_APP, MUTATE_UPDATE_APP, QUERY_APPLICATION, QUERY_APPLICATIONS} from "../queries";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {Link, useLocation, useMatch} from "react-router-dom";
import * as Yup from "yup";
import * as Log from "../../common/log";
import {Formik} from "formik";
import {
    CancelButtonField,
    FormActions,
    SldsFileSelectorField,
    SldsInputField,
    SubmitButtonField
} from "../../common/ui/form/formElements";
import {Form} from "../../common/ui/form/formik";
import {DangerZone} from "../../common/ui/dangerZone";
import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import gql from "graphql-tag";
import {backendUrl} from "../../common/helper";
import Button from "../../common/slds/buttons/button";
import {FrontendConfigContext} from "../../common/context/frontendConfigContext";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";

const AppDetailPage = (props) => {
    const location = useLocation();
    const license = useContext(FeatureContext);
    const {history} = props;
    const match = useMatch("/applications/:id");
    const appId = match.params.id;
    const result = useQuery(QUERY_APPLICATION, {
        variables: {
            appId: appId,
        }
    });

    const [deleteApplication] = useMutation(MUTATE_DELETE_APP, {
        variables: {
            appId: appId
        },
        refetchQueries: [{
            query: QUERY_APPLICATIONS,
        }]
    });

    const [updateApplication] = useMutation(MUTATE_UPDATE_APP, {
        variables: {
            appId: appId
        },
        refetchQueries: [{
            query: QUERY_APPLICATION,
            variables: {
                appId: appId,
            }
        }]
    });

    const organisationListResult = useQuery(gql`
        query($search: String) {
            getOrganisationList(search: $search) {
                id
                name
            }
        }`, {
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        },
        skip: true
    });

    const loading = useGraphqlLoadingComponent(result);
    if (loading) {
        return loading;
    }

    const {data} = result;
    const {appInstance} = data;

    const brandingLogoUrl = React.useContext(FrontendConfigContext)?.brandingConfig?.defaultLogoUrl;
    let logoUrl = backendUrl() + brandingLogoUrl
    if (appInstance.logo !== "default") {
        logoUrl = backendUrl() + "/data/" + appInstance.logo;
    }

    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <Page title={"Application Details"}
                     trail={[<Link to={"."} key={1}>Applications</Link>]}
        ><FeatureNotEnabled/>
        </Page>
    }

    return <Page
        trail={[<Link to="." key={1}>Applications</Link>, <Link to={`${location.pathname}`} key={2}>{appInstance.name}</Link>]}
        title={`${appInstance.name} (${appInstance.appId})`}
        actions={<>
            <Button iconName={"preview"} onClick={() => {
                let win = window.open("/#/app/" + appInstance.id + "/devices/", '_blank');
                win.focus();
            }}>Open App</Button>
        </>}
    >
        <Formik
            initialValues={{
                ...appInstance,
                logo: logoUrl, // Since the server does not know the real URL we override the value here. we could also use a @Client custom resolver in Apollo
            }}
            initialStatus={{
                readOnly: true,
                canEdit: true,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().strict().shape({
                name: Yup.string().required().trim(),
                appId: Yup.string().required().lowercase().matches(/^[a-z0-9-]+$/, "appId must contain only: a-z, 0-9, -").trim()
            })}
            onSubmit={(values, actions) => {
                Log.Debug("submit", values);

                updateApplication({
                    variables: {
                        appId: appId,
                        input: {
                            name: values.name,
                            appId: values.appId,
                            logo: values.logo,
                            organisationId: values.organisation.id
                        }
                    }
                }).then(() => {
                    actions.setStatus({canEdit: true, readOnly: true});
                }).catch((err) => {
                    Log.Error("Failed to update app", err);
                    actions.setFieldError("global", "Failed to update app: " + err.message);
                }).finally(() => {
                    actions.setSubmitting(false);
                });
            }}
        >
            <Form className="slds-m-around--small">
                <div className="slds-grid slds-grid_pull-padded-x-small">
                    <div className="slds-col slds-p-horizontal_x-small">
                        <SldsInputField name={"name"} label={"Name"}/>
                        <SldsInputField name={"appId"} label={"App Id"} readOnly={true}
                                        placeholder={"app-instance-id"}/>
                        <SldsInputField name={"type"} label={"Type"} readOnly={true} placeholder={"app-type"}/>
                        <SingleLookupField name="organisation"
                                           label={"Organisation"}
                                           loadSuggestions={(keyword) => organisationListResult.refetch({search: keyword})
                                          .then(result => result.data.getOrganisationList)
                                      }
                                           placeholder={"Select Organisation ..."}
                                           titleExtractor={it => it.name}
                                           subtitleExtractor={it => it.id}
                        />
                        <FormActions>
                            <SubmitButtonField>Save</SubmitButtonField>
                            <CancelButtonField/>
                        </FormActions>
                    </div>
                    <div className="slds-col slds-p-horizontal_x-small">
                        {/* <img src={"/static/img/logos/lobaro.svg"} alt={"Logo"} width={"200px"}/>*/}
                        <SldsFileSelectorField name="logo" label={"Logo"} withThumb={true} />
                    </div>
                </div>
            </Form>
        </Formik>

        <DangerZone>
            <Button type={"button"} variant={"destructive"} onClick={() => {
                if (!window.confirm("Delete Application permanently?")) {
                    return;
                }
                deleteApplication().then(() => {
                    history.push("/configuration/applications");
                }).catch((err) => {
                    alert("Failed to delete Application: " + err.message);
                });
            }}>Delete Application</Button>
        </DangerZone>
    </Page>;
};

export default AppDetailPage;