import React, {useContext} from "react";
import {Field, Form, Formik} from "formik";
import {SldsInput, SldsTextarea,} from "../../../common/ui/form/formElements";
import {useMutation, useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {useT} from "../../../common/i18n";
import {useParams} from "react-router";
import {useNotificationContext} from "../../../notifications/notificationContext";
import Button from "../../../common/slds/buttons/button";
import {useAuthContext} from "../../../common/context/authContext";
import * as log from "../../../common/log";
import {FeatureContext} from "../../../common/context/featureContext";
import {FeatureNotEnabled} from "../../../common/featureNotEnabled";

const QUERY_DEVICE = gql`
    query device($devId: ID!) {
        device(id: $devId) {
            id
            deviceCertificate {
                id
                deviceCertificateHash
                trustStatus
                createdAt
                updatedAt
            }
            certEnforcesDtls
        }
    }
`;

const DELETE_DEVICE_CERTIFICATE = gql`
    mutation deleteDeviceCertificate($id: ID!) {
        deleteDeviceCertificate(id: $id) {
            id
        }
    }
`;

export default function DeviceSecurity() {
    const license = useContext(FeatureContext)
    const t = useT();
    const devId = useParams().deviceId;
    const notify = useNotificationContext();
    const auth = useAuthContext();


    const deviceResult = useQuery(QUERY_DEVICE, {
        fetchPolicy: 'cache-first',
        variables: {devId: devId},
    });

    const [deleteDeviceCertMutation] = useMutation(DELETE_DEVICE_CERTIFICATE);

    const loading = useGraphqlLoadingComponent(deviceResult);
    if (loading) {
        return loading;
    }

    const {device} = deviceResult.data;

    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <FeatureNotEnabled/>
    }

    if (device.deviceCertificate == null) {
        return <div className="slds-m-left--x-small">
            {t("device.security.no-cert-assigned", "No associated DTLS Certificate for Device. Device will fallback to default communication.")}
            </div>
    }
    const deviceRes = JSON.parse(JSON.stringify(device));
    if (deviceRes.deviceCertificate?.createdAt) {
        const parsedDate = new Date(deviceRes.deviceCertificate.createdAt);
        deviceRes.deviceCertificate.createdAt = parsedDate.toString() !== "Invalid Date" ? parsedDate.toLocaleString() : deviceRes.deviceCertificate.createdAt;
    }
    if (deviceRes.deviceCertificate?.updatedAt) {
        const parsedDate = new Date(deviceRes.deviceCertificate.updatedAt);
        deviceRes.deviceCertificate.updatedAt = parsedDate.toString() !== "Invalid Date" ? parsedDate.toLocaleString() : deviceRes.deviceCertificate.updatedAt;
    }

    return <div className="slds-m-left--x-small">
        {t("device.security.certEnforcesDtls","Enforce DTLS if Cert is present")}&nbsp;
        <input type="checkbox" name="certEnforcesDtls" id={"certEnforcesDtls"} checked={deviceRes.certEnforcesDtls}
               readOnly={true}/>
        <Formik
            initialValues={deviceRes}
            enableReinitialize={true}
            initialStatus={{
                readOnly: true,
                canEdit: false //isAdmin || isOrgAdmin
            }}
            render={(formik) => {
                const {readOnly} = formik.status;

                return <Form className="slds-form slds-form-element_stacked">
                    <Field component={SldsInput} name="deviceCertificate.id"  id="deviceCertificate.id" label={t("device.security.certificate-id", "Certificate Id")}
                           readOnly={readOnly}/>
                    <Field component={SldsTextarea} name="deviceCertificate.deviceCertificateHash"  id="deviceCertificate.deviceCertificateHash" label={t("device.security.certificate-hash", "Certificate Fingerprint")}/>
                    <Field component={SldsTextarea} name="deviceCertificate.trustStatus"  id="deviceCertificate.trustStatus" label={t("device.security.trust-status", "Trust Status")}/>
                    <Field component={SldsTextarea} name="deviceCertificate.updatedAt"  id="deviceCertificate.updatedAt" label={t("device.security.updated-at", "Updated At")}/>
                    <Field component={SldsTextarea} name="deviceCertificate.createdAt"  id="deviceCertificate.createdAt" label={t("device.security.created-at", "Created At")}/>
                </Form>;
            }}
        />
        {auth.hasRole("org-admin", "admin", "device-admin") &&
        <Button iconCategory={"utility"} iconName={"delete"} onClick={() => {
            deleteDeviceCertMutation({
                variables: {id: deviceRes.deviceCertificate.id},
            }).then(() => {
                notify.success(t("device.security.delete-certificate-success", "Successfully deleted certificate"))
                deviceResult.refetch()
            }).catch((err) => {
                log.Error("failled to delete device certificate", err);
                notify.error(t("device.security.delete-certificate-error", "Error deleting certificate"))
                })
        }}>{t("device.security.delete-certificate", "Delete Certificate")}</Button>
        }
    </div>
}