import { createHelpers } from "../../template"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import DataTableCell from "../data-table/cell"
import PropTypes from "prop-types"
import { Modal } from "../../slds"
import ErrorBoundary from "../errorBoundary"
import Json from "../json"
import FilterPanel from "../filterPanel"
import { Log } from "../../log"
import { HasPermission } from "../permissions"
import { GenericDataTableConfigForm } from "./genericDataTableConfigForm"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { AppContext } from "../../../app/appPage"
import { Formik } from "formik"
import { CancelButtonField, FormActions, SubmitButtonField } from "../form/formElements"
import { useSort } from "../../hooks/useSort"
import { useAppTableConfig } from "./useAppTableConfig"
import { useT } from "../../i18n"
import { Button, Card, Col, ConfigProvider, Dropdown, Flex, Row, Space, Switch, Table, Tabs, Tooltip } from "antd"
import { ColumnBooleanFilter, ColumnTextFilter, FilterTypeArray, FilterTypeBinary } from "./columnFilter"
import Icon, { DownloadOutlined, DownOutlined, FilterFilled, RedoOutlined, RightOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons"
import DebouncedPagination from "../debouncedPagination"
import { TemplateTableCell } from "./TemplateTableCell"
import { SearchInput } from "./SearchInput"
import { TableSettingsSVG } from "../../svg/iconSVGs"
import { useNotificationContext } from "../../../notifications/notificationContext"
import ExportDevicesDialog from "./exportDevicesDialog"
import { TablePinConfigurationDialog, TableVisibilityConfigurationDialog } from "./TableConfigurationDialogs"
import { Resizable } from "react-resizable"

// registers multiple useful handlebar helpers
createHelpers()

// Safely get the length of an array, returning 0 for invalid input.
const count = (array) => (Array.isArray(array) ? array.length : 0)

const defaultCellWidth = "10rem"
const defaultInfoCellWidth = "100rem"

const tableColumnVisibilityPrefix = "lobaro.tcv."
const tableColumnPinPrefix = "lobaro.tcp."
const tableColumnWidthPrefix = "lobaro.tcw."
const fallbackVisibility = true
const tooltipDelay = 0.5
const buttonStyle = { color: "black" }

// Columns that should always be visible - based on the key set in the antd column
const unchangeableColumns = ["addr", "serial"]

const TimeDataTableCell = ({ children, ...props }) => (
    <DataTableCell title={children} {...props}>
        <div>{moment(children).format(props.format ? props.format : "DD.MM.YYYY HH:mm:ss")}</div>
    </DataTableCell>
)
TimeDataTableCell.displayName = DataTableCell.displayName

const ShowDetailsTableCell = (props) => {
    return (
        <DataTableCell title={"Show Details"} style={{ width: "3.25rem" }} {...props}>
            <div>
                <Button
                    assistiveText={{ icon: "Show Details" }}
                    iconCategory="utility"
                    iconName="open" // preview, record_lookup, open
                    iconVariant="container" // container, border
                    variant="icon"
                    onClick={() => props.showDetails(props.item)}
                />
            </div>
        </DataTableCell>
    )
}
ShowDetailsTableCell.displayName = DataTableCell.displayName

const tableConfigShape = PropTypes.shape({
    cols: PropTypes.arrayOf(
        PropTypes.shape({
            width: PropTypes.string,
            heading: PropTypes.string.isRequired,
            hasFilter: PropTypes.bool,
            cell: PropTypes.shape({
                format: PropTypes.string.isRequired,
                href: PropTypes.string,
                isHtml: PropTypes.bool,
            }),
        })
    ),
})

const MUTATE_CREATE_OR_UPDATE_APP_TABLE_CONFIG = gql`
    mutation deviceTableConfig($appId: ID!, $name: String!, $tableConfig: AppTableConfigInputType!) {
        createOrUpdateAppTableConfig(appId: $appId, name: $name, input: $tableConfig) {
            id
            appId
            name
            config
        }
    }
`

const ResizeableTitle = (props) => {
    const { onResize, width, ...restProps } = props
    if (!width) {
        return <th {...restProps} />
    }
    return (
        <Resizable
            width={width}
            height={0}
            handle={<span className="react-resizable-handle" onClick={(e) => e.stopPropagation()} />}
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}>
            <th {...restProps} />
        </Resizable>
    )
}

/**
 * Generic data table can handle paging, filtering and sorting using by passing in the results from "usePage", "useFilter", "useSort"
 * A gqlResult can be passed to support reloading of data (refetch)
 * */
export default function GenericAntDataTable(props) {
    const t = useT()
    const notify = useNotificationContext()

    let {
        items,
        hideDetails,
        gqlResult,
        // use Loading Spinner while query is loading
        useGqlLoadingSpinner,
        csvExport,
        hardwareCsvExport,
        // Table config
        tableConfigName,
        prefixCols,
        tableConfigDefault,
        handleTableConfigSave,
        // Filtering
        filters,
        search,
        filterField,
        // Events
        page,
        // Sorting
        sort,
        // Selection
        selection,
        selectionColumnWidth,
    } = props

    if (!prefixCols) {
        prefixCols = []
    }

    // If set, filter data timewise by this column. Default is "receivedAt" (was previously "createdAt")
    if (!filterField) {
        filterField = "receivedAt"
    }

    //TODO useSortLight
    if (!sort) {
        sort = useSort()
    }

    if (items === null || items === undefined) {
        items = []
    }

    if (page) {
        props.page.setPageItemCount(count(items))
    }

    const canEditTable = !!tableConfigName || handleTableConfigSave

    const app = useContext(AppContext)

    const [settingsOpen, setSettingsOpen] = useState(false)
    const filterTabVisible = filters?.filterTabVisible
    const setFilterTabVisible = filters?.setFilterTabVisible
    const { loading: loadingTableConfig, tableConfig: tableConfig } = useAppTableConfig(tableConfigName, tableConfigDefault)
    const [mutateTableConfig] = useMutation(MUTATE_CREATE_OR_UPDATE_APP_TABLE_CONFIG)
    const [useExperimentalResize, setUseExperimentalResize] = useState(false)

    if (loadingTableConfig) {
        return loadingTableConfig
    }

    // TODO: This executes an second graphQL query
    // So better execute "useAppTableConfig" outside of this component
    // and set the filter before fetching data
    // Alternatively we could delegate all data loading to the GenericDataTable
    /*
if (tableConfig.defaultFilter) {
Log.Debug("Default Filter:", tableConfig.defaultFilter);
filters.setFilterString(tableConfig.defaultFilterString)
}
*/

    // fullTableConfig contains also prefixCols
    let fullTableConfig = { ...tableConfig }
    fullTableConfig.cols = [...prefixCols, ...(tableConfig?.cols || [])]

    const onCancel = () => {
        setSettingsOpen(false)
    }

    function restoreDefault(formik) {
        if (handleTableConfigSave) {
            return Promise.resolve(handleTableConfigSave(null, formik)).then(() => {
                setSettingsOpen(false)
            })
        }

        return mutateTableConfig({
            variables: {
                appId: app.id,
                name: tableConfigName,
                tableConfig: {
                    config: JSON.stringify(null),
                },
            },
        })
            .then(() => {
                setSettingsOpen(false)
            })
            .catch((err) => {
                Log.Error("Failed to save AppTableConfig", err)
                alert(err.message)
            })
    }

    let loading = undefined
    if (gqlResult && useGqlLoadingSpinner) {
        loading = gqlResult.loading
    }

    let antdData = items.map((item) => {
        return {
            key: item.id,
            ...item,
        }
    })

    let antdSelections = selection?.selections?.map((s) => {
        return s.id
    })

    let hasCsvExport = fullTableConfig?.cols?.some((tc) => tc.csvFormat?.length > 0) && csvExport

    const resetUIFilterValues = () => {
        setResetFilters((prevState) => prevState + 1)
        setHighlightFilters(() => {
            return {}
        })
    }

    // Wraps the confirm function of the antd table filter dropdown to store the confirmed filter values to be
    // able to highlight the filter values in the table
    const highlightConfirm = (fieldName, fieldValue, baseConfirm) => {
        setHighlightFilters((prev) => {
            return { ...prev, [fieldName]: fieldValue }
        })
        //could be handled more efficiently if the filter was connected to te page hook, but that would break backwards compatibility
        page.reset()
        baseConfirm()
    }

    const setOverallHighlightValue = (fieldValue) => {
        setHighlightFilters((prev) => {
            return { ...prev, ["search_all"]: fieldValue }
        })
    }

    const getColumnSearchProps = (_dataIndex, filter, label) => ({
        filterDropdown: ({ confirm }) => (
            <>
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    {filter.type === "string" && (
                        <ColumnTextFilter
                            key={filter.property}
                            property={filter.property}
                            filterHook={filters}
                            antdProps={{
                                confirm: (fieldValue) => highlightConfirm(filter.property, fieldValue, confirm),
                                resetValue: resetFilters,
                                filterLabel: label,
                                resetUIFilterValues: resetUIFilterValues,
                            }}
                        />
                    )}
                    {filter.type === "binary" && (
                        <ColumnTextFilter
                            key={filter.property}
                            property={filter.property}
                            filterHook={filters}
                            filterType={FilterTypeBinary}
                            antdProps={{
                                confirm: (fieldValue) => highlightConfirm(filter.property, fieldValue, confirm),
                                resetValue: resetFilters,
                                filterLabel: label,
                                resetUIFilterValues: resetUIFilterValues,
                            }}
                        />
                    )}
                    {filter.type === "list" && (
                        <ColumnTextFilter
                            key={filter.property}
                            property={filter.property}
                            filterHook={filters}
                            filterType={FilterTypeArray}
                            antdProps={{
                                confirm: (fieldValue) => highlightConfirm(filter.property, fieldValue, confirm),
                                resetValue: resetFilters,
                                filterLabel: label,
                                resetUIFilterValues: resetUIFilterValues,
                            }}
                        />
                    )}
                    {filter.type === "boolean" && (
                        <ColumnBooleanFilter
                            key={filter.property}
                            property={filter.property}
                            filterHook={filters}
                            antdProps={{
                                confirm: confirm,
                                resetValue: resetFilters,
                                filterLabel: label,
                                resetUIFilterValues: resetUIFilterValues,
                            }}
                        />
                    )}
                </div>
            </>
        ),
        filterIcon: () => {
            // show search icon as blue if filter is active
            let searchActive = filters
                ?.getGraphqlFilterInput()
                ?.map((filterInput) => filterInput.field)
                .includes(filter.property)
            return (
                <>
                    <Tooltip
                        title={
                            searchActive ? t("common.search.changeSearch", "Edit active search") : t("common.search.searchField", "Search field") + ` ${label}`
                        }
                        mouseEnterDelay={tooltipDelay}>
                        <SearchOutlined style={{ color: searchActive ? "#1890ff" : undefined }} />
                    </Tooltip>
                </>
            )
        },
    })

    const isString = (x) => {
        return Object.prototype.toString.call(x) === "[object String]"
    }

    const handleResize = (index) => (something) => {
        const newColumns = [...antdColumns]

        const newWidth = isString(newColumns[index]?.width) ? 200 : newColumns[index]?.width
        newColumns[index] = {
            ...newColumns[index],
            width: newWidth + something?.movementX,
            saveWidth: true,
        }
        Log.Debug("Save column width", newColumns[index])
        saveWidthState(newColumns[index])
        setAntdColumns(newColumns)
    }

    const saveWidthState = (newColumn) => {


        let existing = localStorage.getItem(tableColumnWidthPrefix + tableConfig.id)
        let save = []
        Log.Debug("genericAntTable.saveWidthState", "existing", existing)

        if (existing) {
            let entryId = -1
            let existingWidths = JSON.parse(existing)
            if (existingWidths.constructor === Array) {
                // replace the existing width with the new width
                entryId = existingWidths.findIndex((w) => w[0] === newColumn.id)
            }
            Log.Debug("genericAntTable.saveWidthState", "existingWidths", existingWidths, "entryId", entryId)

            if (entryId >= 0) {
                existingWidths[entryId] = [newColumn.id, newColumn.width]
                save = existingWidths
            } else {
                save = [...existingWidths, [newColumn.id, newColumn.width]]
            }
        } else {
            save = [[newColumn.id, newColumn.width]]
        }
        if (save.length > 0) {
            localStorage.setItem(tableColumnWidthPrefix + tableConfig.id, JSON.stringify(save))
        }
    }

    const removeWidthState = () => {
        localStorage.removeItem(tableColumnWidthPrefix + tableConfig.id)
    }

    const convertFullTableConfigToAntTableConfig = (config) => {
        return config?.cols?.map((col, index) => {
            let width = col?.width ? col.width : defaultCellWidth
            width = width !== "auto" ? width : defaultCellWidth

            let antColumn = {
                // set sort property key if available to later easier access it when sorting a column
                key: col.sortProperty ? col.sortProperty : col.heading,
                render: (text) => {
                    if (col?.cell?.isCustomRender) {
                        return <>{col.cell.customRender(text)}</>
                    }
                    if (col?.cell?.format) {
                        // check the highlightFilters for the current column to highlight the filter value
                        let specificHighlight = highlightFilters[col?.filter?.property] ? highlightFilters[col.filter.property] : null
                        let overallHighlight = highlightFilters["search_all"] ? highlightFilters["search_all"] : null
                        return (
                            <>
                                <TemplateTableCell column={col} item={text} highlight={[specificHighlight, overallHighlight]} />
                            </>
                        )
                    }
                },
                sorter: col.sortProperty ? true : false,
                title: col.heading,
                width: width,
                defaultVisible: col?.defaultVisible,
                id: col?.id,
                preOrder: index, //save the original order of the columns
            }

            if (col.filter?.hasFilter) {
                antColumn = {
                    ...antColumn,
                    ...getColumnSearchProps(col.filter.property, col.filter, col.heading),
                }
            }
            return antColumn
        })
    }

    const mergeDefaultVisibility = (config) => {
        return config?.map((col) => {
            let visible = col?.defaultVisible !== undefined && col.defaultVisible !== null ? col.defaultVisible : fallbackVisibility
            return {
                ...col,
                hidden: !visible,
            }
        })
    }

    const mergeLocalStorageVisibility = (config) => {
        if (tableConfig?.id) {
            let localStorageVisibility = localStorage.getItem(tableColumnVisibilityPrefix + tableConfig.id)
            if (localStorageVisibility) {
                let visibility = JSON.parse(localStorageVisibility)
                if (visibility.constructor === Array) {
                    return config.map((col) => {
                        return {
                            ...col,
                            hidden: !visibility.includes(col.id),
                        }
                    })
                }
            }
        }

        return config
    }

    const mergeLocalStoragePin = (config) => {
        if (tableConfig?.id) {
            let localStoragePin = localStorage.getItem(tableColumnPinPrefix + tableConfig.id)
            if (localStoragePin) {
                let pin = JSON.parse(localStoragePin)

                if (pin.constructor === Array) {
                    config = config.map((col) => {
                        let pinned = pin.find((p) => p[0] === col.id)
                        return {
                            ...col,
                            fixed: pinned ? pinned[1] : undefined,
                        }
                    })
                }
            }
        }

        config = pushPinnedColumns(config)

        return config
    }

    const mergeLocalStorageWidth = (config) => {
        if (tableConfig?.id) {
            let localStorageWidth = localStorage.getItem(tableColumnWidthPrefix + tableConfig.id)
            if (localStorageWidth) {
                let width = JSON.parse(localStorageWidth)
                if (width.constructor === Array) {
                    return config.map((col) => {
                        let widthValue = width.find((w) => w[0] === col.id)
                        return {
                            ...col,
                            width: widthValue ? parseInt(widthValue[1]) : col.width,
                        }
                    })
                }
            }
        }
        return config
    }

    const handleTableChange = (pagination, filters, sorter) => {
        // update sorter of this component
        if (sorter) {
            let sortInfo = {
                property: sorter.columnKey,
                direction: sorter.order === "ascend" ? "ASC" : "DESC",
            }
            sort.setSortInfo(sortInfo)
        }

        // on sort go to first page!
        page.setPageInfo({
            offset: 0,
            limit: page.pageInfo.limit,
            total: page.pageInfo.total,
        })
    }

    const handleCheckColumnVisibility = (checkedKeys) => {
        if (tableConfig?.id) {
            localStorage.setItem(tableColumnVisibilityPrefix + tableConfig.id, JSON.stringify(checkedKeys))
        } else {
            Log.Error("genericAntDataTable.handleCheckColumnVisibility", "tableConfigId is missing")
        }

        // will enforce that at least address and serial column are visible
        if (checkedKeys.length === 0) {
            notify.info(t("generic-data-table.notify.no-columns-selected", "No columns have been selected. A minimal set of column has been selected."), 5000)
            setAllColumnsVisible(false)
            return
        }

        setAntdColumns((prev) => {
            return prev?.map((col) => {
                return {
                    ...col,
                    hidden: !checkedKeys.includes(col.id),
                }
            })
        })
    }

    const setAllColumnsVisible = (visible) => {
        if (tableConfig?.id) {
            let save
            if (visible) {
                save = antdColumns.map((col) => col.id)
            } else {
                let filtered = antdColumns.filter((col) => {
                    // Find the columns that are in the unchangeableColumns list and use their id to be persisted in the localStorage.
                    // The filtered columns can be set by the default config or by the config of the user. Either way we have to map the key to the uuid of the column.
                    return unchangeableColumns.includes(col.key)
                })
                // mapping the key to the uuid of the column
                save = filtered.map((col) => col.id)
            }

            localStorage.setItem(tableColumnVisibilityPrefix + tableConfig.id, JSON.stringify(save))
        }

        setAntdColumns((prev) => {
            return prev?.map((col) => {
                if (unchangeableColumns.includes(col.key)) {
                    return {
                        ...col,
                        hidden: false, // always visible
                    }
                }
                return {
                    ...col,
                    hidden: !visible,
                }
            })
        })
    }

    const setDefaultVisibility = () => {
        let visibility
        setAntdColumns((prev) => {
            visibility = mergeDefaultVisibility(prev)
            return visibility
        })
        localStorage.removeItem(tableColumnVisibilityPrefix + tableConfig.id)
    }

    const convertToColumnVisibility = (cols) => {
        if (!cols) {
            return []
        }
        return cols?.map((col) => {
            return {
                title: col.title,
                key: col.id,
                id: col.id,
                checked: !col.hidden,
                preOrder: col.preOrder, // used to reorder values based on the initial order
            }
        })
    }

    const convertToColumnPin = (cols) => {
        if (!cols) {
            return []
        }
        return cols?.map((col) => {
            return {
                name: col.title,
                fixed: col.fixed,
                key: col.id,
                preOrder: col.preOrder, // used to reorder values based on the initial order
            }
        })
    }

    // sort based on the prePinColumns order
    const prePinColumnSorter = (a, b) => {
        return a?.preOrder > b?.preOrder ? 1 : a?.preOrder < b?.preOrder ? -1 : 0
    }

    // pushes pinned columns that are fixed right to the start of the array,
    // pinned columns that are fixed left to the end of the array,
    // and recovers the original column order for all not pinned columns.
    const pushPinnedColumns = (config) => {
        let left = config.filter((col) => col.fixed === "left")
        let right = config.filter((col) => col.fixed === "right")
        let center = config.filter((col) => col.fixed === undefined || col.fixed === null)

        // sort based on the prePinColumns order
        left.sort(prePinColumnSorter)
        center.sort(prePinColumnSorter)
        right.sort(prePinColumnSorter)

        config = left.concat(center).concat(right)
        return config
    }

    const [resetFilters, setResetFilters] = useState(0)
    const [highlightFilters, setHighlightFilters] = useState({})

    let antdColumnsConfig = mergeLocalStorageWidth(
        mergeLocalStoragePin(mergeLocalStorageVisibility(mergeDefaultVisibility(convertFullTableConfigToAntTableConfig(fullTableConfig))))
    )

    const [antdColumns, setAntdColumns] = useState(antdColumnsConfig)

    useEffect(() => {
        // Looks fishy to depend the effect on tableConfig and not use the variable fullTableConfig, but is correct because the fullTableConfig is derived from tableConfig.
        // Because the fullTableConfig is recreated (new reference) in every render step, we would create an infinite loop if we would depend on fullTableConfig.
        let columnsConfig = mergeLocalStorageWidth(
            mergeLocalStoragePin(mergeLocalStorageVisibility(mergeDefaultVisibility(convertFullTableConfigToAntTableConfig(fullTableConfig))))
        )
        setAntdColumns(columnsConfig)
    }, [tableConfig, useExperimentalResize])

    const savePinState = (newColumns) => {
        let save = newColumns
            .filter((col) => col.fixed === "left" || col.fixed === "right")
            .map((col) => {
                return [col.id, col.fixed]
            })
        if (save.length > 0) {
            localStorage.setItem(tableColumnPinPrefix + tableConfig.id, JSON.stringify(save))
        } else {
            localStorage.removeItem(tableColumnPinPrefix + tableConfig.id)
        }
    }

    const handleOnPin = (checkedKey, side) => {
        let newColumns = antdColumns.map((col) => {
            if (col.id === checkedKey) {
                return {
                    ...col,
                    fixed: side,
                }
            }
            return col
        })

        // push columns to the start or end of the array if they are pinned
        newColumns = pushPinnedColumns(newColumns)
        savePinState(newColumns)
        setAntdColumns(newColumns)
    }
    const handlePinReset = () => {
        let noPinColumns = antdColumns.map((col) => {
            return {
                ...col,
                fixed: null,
            }
        })

        noPinColumns = pushPinnedColumns(noPinColumns)
        savePinState(noPinColumns)
        setAntdColumns(noPinColumns)
    }

    const columnSettingsTabs = [
        {
            key: "gTableSettingsTab1",
            label: (
                <Tooltip mouseEnterDelay={1} title={t("generic-data-table.tooltip.column-visibility.explanation", "Show or hide columns")}>
                    <span>{t("generic-data-table.tooltip.column-visibility.title", "Column Visibility")}</span>
                </Tooltip>
            ),
            children: (
                <TableVisibilityConfigurationDialog
                    // sort based on the prePinColumns order, so that the UI does not change the visible items order when pinning a column left or right
                    treeData={convertToColumnVisibility(antdColumns)}
                    onCheck={handleCheckColumnVisibility}
                    onClickAll={() => {
                        setAllColumnsVisible(true)
                    }}
                    onClickDefault={() => {
                        setDefaultVisibility()
                    }}
                    onClickMinimal={() => {
                        setAllColumnsVisible(false)
                    }}
                />
            ),
        },
        {
            key: "gTableSettingsTab2",
            label: (
                <Tooltip
                    mouseEnterDelay={1}
                    title={t(
                        "generic-data-table.column-settings.column-pin.explanation",
                        "Pin Columns left or right of the table to make them always visible when scrolling."
                    )}>
                    <span>{t("generic-data-table.column-settings.column-pin.title", "Pinned Columns")}</span>
                </Tooltip>
            ),
            children: (
                <TablePinConfigurationDialog
                    // sort based on the prePinColumns order, so that the UI does not change the visible items order when pinning a column left or right
                    pinData={convertToColumnPin(antdColumns)}
                    handleOnPin={handleOnPin}
                    handlePinReset={handlePinReset}
                />
            ),
        },
        {
            key: "gTableSettingsTab3",
            label: (
                <Tooltip
                    mouseEnterDelay={1}
                    title={t("generic-data-table.column-settings.experimental.explanation", "Experimental features that might not work as expected.")}>
                    <span>{t("generic-data-table.column-settings.experimental.title", "Experimental")}</span>
                </Tooltip>
            ),
            children: (
                <Flex vertical gap="middle">
                    <Tooltip
                        mouseEnterDelay={1}
                        title={t("generic-data-table.column-settings.experimental.resize.explanation", "Resize the table columns via drag and drop")}>
                        <Flex justify="flex-start" align="center" gap="large">
                            <div>Experimental Resize:</div>
                            <Switch checked={useExperimentalResize} onChange={() => setUseExperimentalResize(!useExperimentalResize)} />
                        </Flex>
                    </Tooltip>
                    <Tooltip
                        mouseEnterDelay={1}
                        title={t("generic-data-table.column-settings.experimental.resize.reset.explanation", "Remove the local stored width")}>
                        <Space.Compact direction="vertical" block>
                            <Button
                                danger
                                type="dashed"
                                onClick={() => {
                                    removeWidthState()
                                    // I don't know why this does not tirger a rerender. So in this case reload the page
                                    setUseExperimentalResize(false)
                                    window.location.reload()
                                }}>
                                {t("generic-data-table.column-settings.experimental.resize.reset.title", "Reset Width")}
                            </Button>
                        </Space.Compact>
                    </Tooltip>
                </Flex>
            ),
        },
    ]

    const rowSelection = selection && {
        preserveSelectedRowKeys: true,
        selectedRowKeys: antdSelections,
        onChange: (selectedRowKeys, selectedRows) => {
            selection.handleSelectionEvent(selectedRowKeys, { selection: selectedRows })
        },
    }

    // will be activated if the user activates the d&d resize feature
    const resizableColumns = antdColumns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => {
            return {
                width: column.width,
                onResize: handleResize(index),
            }
        },
    }))

    return (
        <>
            <ErrorBoundary>
                <Formik
                    initialValues={tableConfig || {}}
                    onSubmit={(values, actions) => {
                        if (handleTableConfigSave) {
                            return Promise.resolve(handleTableConfigSave(values, actions)).then(() => {
                                setSettingsOpen(false)
                            })
                        }

                        return mutateTableConfig({
                            variables: {
                                appId: app.id,
                                name: tableConfigName,
                                tableConfig: {
                                    config: JSON.stringify(values),
                                },
                            },
                        })
                            .then(() => {
                                setSettingsOpen(false)
                            })
                            .catch((err) => {
                                Log.Error("Failed to save AppTableConfig", err)
                                alert(err.message)
                            })
                            .finally(() => {
                                actions.setSubmitting(false)
                            })
                    }}>
                    {(formik) => {
                        return (
                            <Modal
                                isOpen={settingsOpen}
                                dismissOnClickOutside={false}
                                onRequestClose={() => setSettingsOpen(false)}
                                heading={t("generic-data-table.settings.edit-columns", "Edit Columns")}
                                footer={
                                    <FormActions>
                                        <SubmitButtonField formId={"generic-table-config-form"}>{t("common.button.save", "Save")}</SubmitButtonField>
                                        <CancelButtonField onClick={onCancel} />
                                    </FormActions>
                                }>
                                <GenericDataTableConfigForm
                                    id={"generic-table-config-form"}
                                    tableConfig={tableConfig}
                                    formik={formik}
                                    onCancel={onCancel}
                                    onRestoreDefault={() => restoreDefault(formik)}
                                />
                            </Modal>
                        )
                    }}
                </Formik>
            </ErrorBoundary>
            <ErrorBoundary>
                <Row gutter={[8, 16]}>
                    <Col span={24}>
                        {filterTabVisible /* Render filters when we can apply them */ ? (
                            <FilterPanel size="full" align="center" filters={filters} fieldName={filterField} showQuickAction={true} pagination={page} />
                        ) : null}
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1rem" }} gutter={[8, 16]}>
                    <Col span={8}>
                        {page && (
                            <Space size="small">
                                <div />
                                <DebouncedPagination page={page} />
                            </Space>
                        )}
                    </Col>

                    <Col span={16}>
                        <Flex justify="flex-end">
                            <Space size="middle">
                                {count(props.selection?.selections) ? (
                                    <div className="slds-text-title_bold">
                                        {t("generic-data-table.items-selected", "{{count}} items selected", { count: count(props.selection?.selections) })}{" "}
                                        <button onClick={(e) => selection.handleSelectionEvent(e, { selection: [] })} className="slds-button">
                                            {t("common.button.clear", "Clear")}
                                        </button>
                                    </div>
                                ) : null}
                                {search && (
                                    <Tooltip title={t("common.search.overall", "Overall search")} mouseEnterDelay={tooltipDelay}>
                                        <div style={{ width: "25rem", minWidth: "10rem" }}>
                                            <SearchInput search={search} page={page} highlight={setOverallHighlightValue} />
                                        </div>
                                    </Tooltip>
                                )}
                                <Space.Compact block>
                                    {gqlResult && (
                                        <Tooltip title={t("generic-data-table.tooltip.refetch", "Reload Data")} mouseEnterDelay={tooltipDelay}>
                                            <Button icon={<RedoOutlined />} style={buttonStyle} onClick={() => gqlResult.refetch()} />
                                        </Tooltip>
                                    )}
                                    <Tooltip title={t("generic-data-table.column-settings.title", "Column Settings")} mouseEnterDelay={tooltipDelay}>
                                        <Dropdown
                                            dropdownRender={() => (
                                                <>
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Card: {
                                                                    headerBg: "rgba(5, 5, 5, 0.04)",
                                                                },
                                                            },
                                                        }}>
                                                        <Card
                                                            style={{ width: "18rem" }}
                                                            size="small"
                                                            title={t("generic-data-table.column-settings.title", "Column Settings")}>
                                                            <Tabs defaultActiveKey="gTableSettingsTab1" items={columnSettingsTabs} />
                                                        </Card>
                                                    </ConfigProvider>
                                                </>
                                            )}
                                            trigger={["click"]}>
                                            <Button icon={<Icon component={TableSettingsSVG} style={buttonStyle} {...props} />} />
                                        </Dropdown>
                                    </Tooltip>
                                    <HasPermission role={"admin"} hidden={!canEditTable}>
                                        <Tooltip title={t("generic-data-table.settings.edit-columns", "Edit Columns")} mouseEnterDelay={tooltipDelay}>
                                            <Button icon={<SettingOutlined />} style={buttonStyle} onClick={() => setSettingsOpen(true)} />
                                        </Tooltip>
                                    </HasPermission>
                                    {(hasCsvExport || hardwareCsvExport) && (
                                        <Tooltip title={t("devices.bulkoperation.export", "Export Devices")} mouseEnterDelay={tooltipDelay}>
                                            <Dropdown
                                                dropdownRender={() => (
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Card: {
                                                                    headerBg: "rgba(5, 5, 5, 0.04)",
                                                                },
                                                            },
                                                        }}>
                                                        <ExportDevicesDialog
                                                            selection={selection}
                                                            tableConfig={fullTableConfig}
                                                            csvExport={csvExport}
                                                            hardwareCsvExport={hardwareCsvExport}
                                                        />
                                                    </ConfigProvider>
                                                )}
                                                trigger={["click"]}>
                                                <Button icon={<DownloadOutlined />} style={buttonStyle} />
                                            </Dropdown>
                                        </Tooltip>
                                    )}
                                    {tableConfig?.hasFilter && (
                                        <Tooltip title={t("data-table-config-form.show-filter", "Show Filter")} mouseEnterDelay={tooltipDelay}>
                                            <Button icon={<FilterFilled />} style={buttonStyle} onClick={() => setFilterTabVisible(!filterTabVisible)} />
                                        </Tooltip>
                                    )}
                                </Space.Compact>

                                <div />
                            </Space>
                        </Flex>
                    </Col>
                </Row>
                <Row gutter={[8, 16]}>
                    <Col>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Table: {
                                        selectionColumnWidth: selectionColumnWidth ? selectionColumnWidth : "1.75rem",
                                    },
                                },
                            }}>
                            <Table
                                loading={loading}
                                dataSource={antdData}
                                columns={useExperimentalResize ? resizableColumns : antdColumns}
                                rowSelection={rowSelection}
                                expandable={{
                                    expandedRowRender: (record) => {
                                        if (props.renderDetails) {
                                            return <ErrorBoundary>{props.renderDetails(record)}</ErrorBoundary>
                                        }
                                        return (
                                            <div>
                                                <Json style={{ maxWidth: defaultInfoCellWidth }} json={_.omit(record, "key")} />
                                            </div>
                                        )
                                    },
                                    expandIcon: ({ expanded, onExpand, record }) =>
                                        expanded ? (
                                            <DownOutlined onClick={(e) => onExpand(record, e)} />
                                        ) : (
                                            <RightOutlined onClick={(e) => onExpand(record, e)} />
                                        ),
                                    rowExpandable: () => !hideDetails,
                                    columnWidth: "2em",
                                }}
                                size="small"
                                scroll={{ x: "100rem" }}
                                sticky={true}
                                fixedTop={true}
                                tableLayout={"fixed"}
                                showHeader={true}
                                pagination={{
                                    position: ["none", "none"],
                                    pageSize: antdData.length,
                                }}
                                sortDirections={["ascend", "descend", "ascend"]} // prevent sorter from setting a default sort order once sorting was triggered
                                onChange={handleTableChange}
                                locale={{
                                    triggerAsc: t("common.sort.ascending", "Click to sort ascending"),
                                    triggerDesc: t("common.sort.descending", "Click to sort descending"),
                                }}
                                components={
                                    useExperimentalResize
                                        ? {
                                              header: {
                                                  cell: ResizeableTitle,
                                              },
                                          }
                                        : {}
                                }
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
                {page && (
                    <Row style={{ marginTop: "1rem", marginBottom: "1rem" }} gutter={[8, 16]}>
                        <Col>
                            <Space size="small">
                                <div />
                                <DebouncedPagination page={page} />
                            </Space>
                        </Col>
                    </Row>
                )}
            </ErrorBoundary>
        </>
    )
}

GenericAntDataTable.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.array, // Empty table is allowed
    selectedItem: PropTypes.any,
    onSelectItem: PropTypes.func,

    tableConfigDefault: tableConfigShape, // The default table config when tableConfigName is used. - DEPRECATED
    tableConfigName: PropTypes.string, // if set, the table config is loaded from the "app_table_config" DB table. - DEPRECATED
    handleTableConfigSave: PropTypes.func, // if set, the table config can be edited and needs to be saved by the handle function
    prefixCols: PropTypes.array, // List of columns to prefix before tableConfig

    renderDetails: PropTypes.func, // Render a detail component that is shown when a line is expanded
    hideDetails: PropTypes.bool, // Hide the details even if renderDetails is set

    fixedLayout: PropTypes.bool,

    // Pass result of "useFilter" hook to support filtering
    filters: PropTypes.object,
    // Pass result of "useSearch" hook to support search
    search: PropTypes.object,
    // Pass result of "useSort" hook to support sorting
    sort: PropTypes.object,
    // Pass result of "usePagination" hook to support pagination
    page: PropTypes.object,

    gqlResult: PropTypes.object, // Allow to handover a GraphQL result to handle sorting. TODO: could be moved to useSort Hook maybe?
    useGqlLoadingSpinner: PropTypes.bool, // use Loading Spinner on gqlResult if still loading
    csvExport: PropTypes.object, // Optional useCsvExport hook. If not present, the items are used for the export
    hardwareCsvExport: PropTypes.object, // Optional useHardwareCsvExport hook. If not present no hardware export dialoge will be rendered
}
