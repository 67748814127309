import React, {useContext} from "react";
import Page from "../../common/ui/page";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Form} from "../../common/ui/form/formik";
import {Formik} from "formik";
import {FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import * as Log from "../../common/log";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_CREATE_APP} from "../queries";
import * as Yup from 'yup';
import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import gql from "graphql-tag";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";

const NewAppPage = () => {
    const location = useLocation();
    const license = useContext(FeatureContext);

    const [createApp] = useMutation(MUTATE_CREATE_APP, {});

    const organisationListResult = useQuery(gql`
        query($search: String) {
            getOrganisationList(search: $search) {
                id
                name
            }
        }`, {
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        },
        skip: true
    });

    const navigate = useNavigate();

    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <Page title={"Create Application"}
                     trail={[<Link to={"."} key={1}>Applications</Link>, <Link to={location.pathname} key={2}>New</Link>]}
        ><FeatureNotEnabled/>
        </Page>
    }


    return <Page title={"Create Application"}
                 trail={[<Link to={"."} key={1}>Applications</Link>, <Link to={location.pathname} key={2}>New</Link>]}
    >
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            validationSchema={Yup.object().strict().shape({
                name: Yup.string().required().trim(),
                appId: Yup.string().required().lowercase().matches(/^[a-z0-9-]+$/, "appId must contain only: a-z, 0-9, -").trim(),
                organisation: Yup.object().required()
            })}
            onSubmit={(values, actions) => {
                Log.Debug("submit", values);

                createApp({
                    variables: {
                        newApp: {
                            name: values.name,
                            appId: values.appId,
                            organisationId: values.organisation.id,
                        }
                    }
                }).then((result) => {
                    navigate("/configuration/applications/" + result.data.createApplication.id);
                }).catch((err) => {
                    Log.Error("Failed to create app", err);
                    actions.setFieldError("global", "Failed to create app: " + err.message);
                }).finally(() => {
                    actions.setSubmitting(false);
                })
            }}
        >
            {
                (formik) => {
                    Log.Debug("Formik: ", formik);
                    return <Form className="slds-m-around--small">
                        <SldsInputField name={"name"} label={"Name"} placeholder={"Application Name"}/>
                        <SldsInputField name={"appId"} label={"App Id"} placeholder={"app-instance-id"}/>
                        <SingleLookupField name={"organisation"} label={"Organisation"}
                                           loadSuggestions={(keyword) => organisationListResult
                                          .refetch({search: keyword})
                                          .then(result => result.data.getOrganisationList)
                                      }
                                           titleExtractor={it => it.name}
                                           subtitleExtractor={it => it.id}
                        />
                        <FormActions>
                            <SubmitButtonField>Create</SubmitButtonField>
                        </FormActions>
                    </Form>
                }
            }
        </Formik>


    </Page>

};
export default NewAppPage