import { createHelpers } from "../../template"
import moment from "moment"
import React, { Fragment, useContext, useEffect, useState } from "react"
import DataTableCell from "../data-table/cell"
import DataTableColumn from "../data-table/column"
import DataTable from "../data-table"
import PropTypes from "prop-types"
import { Icon } from "../../slds/icons/icon"
import { Modal } from "../../slds"
import ErrorBoundary from "../errorBoundary"
import Json from "../json"
import FilterPanel from "../filterPanel"
import * as log from "../../log"
import { Log } from "../../log"
import { ButtonGroup } from "@salesforce/design-system-react"
import { HasPermission } from "../permissions"
import { GenericDataTableConfigForm } from "./genericDataTableConfigForm"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { AppContext } from "../../../app/appPage"
import { Link } from "react-router-dom"
import _ from "underscore"
import { Formik } from "formik"
import { CancelButtonField, FormActions, SubmitButtonField } from "../form/formElements"
import { useSort } from "../../hooks/useSort"
import { useAppTableConfig } from "./useAppTableConfig"
import Pagination from "../pagination"
import { useGraphqlLoadingComponent } from "../../graphql"
import { download } from "../../download"
import { compileHandlebars } from "../../cachedHandlebarsCompiler"
import { toCsvLine } from "../../hooks/useCsvExport"
import Button from "../../slds/buttons/button"
import { useT } from "../../i18n"
import { ColumnBooleanFilter, ColumnTextFilter } from "./columnFilter"

createHelpers()

// Safely get the length of an array, returning 0 for invalid input.
const count = (array) => (Array.isArray(array) ? array.length : 0)

const exportCsv = (tableConfig, items, gqlExport) => {
    Log.Debug("exportCsv.exportCsv", tableConfig)
    if (gqlExport) {
        Log.Debug("GQL EXPORT!", gqlExport)
        return gqlExport.export(tableConfig)
    }

    // Should be deprecated
    Log.Debug("Item Based EXPORT!", gqlExport)
    let csvData = ""
    const exportedCols = tableConfig.cols.filter((col) => col.csvFormat?.length > 0)
    csvData += toCsvLine(exportedCols.map((col) => col.heading))

    items.forEach((item) => {
        csvData += toCsvLine(exportedCols.map((col) => compileHandlebars(col.csvFormat || col.cell?.format, item)))
    })

    download(csvData, "export.csv", "text/csv")
}

const TimeDataTableCell = ({ children, ...props }) => (
    <DataTableCell title={children} {...props}>
        <div>{moment(children).format(props.format ? props.format : "DD.MM.YYYY HH:mm:ss")}</div>
    </DataTableCell>
)
TimeDataTableCell.displayName = DataTableCell.displayName

// Post process the given template engine result which we call html
function postProcessTemplate(html) {
    const pattern = /\{\{.*?\}\}|.+?(?=\{\{.*?\}\})|.+/g
    let array = html.match(pattern)

    array = (array || []).map((s, i) => {
        let tok = s.replace(/^\{\{|\}\}$/g, "").split(/[,:]/)
        let func = tok[0]
        let args = tok.slice(1)
        if (func === "icon") {
            let name = args[0]
            let category = args[1]
            return <Icon key={i} name={name} category={category} size={"x-small"} />
        }

        return s
    })

    return array
}

// The "item" can be directly accessed in the format of the cell
// e.g. {{name}} if item.name is a valid property.
const TemplateTableCell = (props) => {
    //Log.Debug("PROPS:", props.item);
    //Log.Debug("COL:", props.column);
    let cell = props.column.cell
    let item = props.item
    if (!cell) {
        cell = {
            format: "- missing cell -",
        }
    }

    let html = "no content"
    let url = undefined

    if (cell.format) {
        html = compileHandlebars(cell.format, item)
    }

    if (cell.href) {
        url = compileHandlebars(cell.href, item)
    }
    if (!cell.isHtml) {
        html = postProcessTemplate(html)
    }

    let cellContent
    if (url) {
        if (url.startsWith("#")) {
            url = url.substr(1)
            cellContent = <Link to={url}>{html}</Link>
        } else {
            cellContent = <a href={url}>{html}</a>
        }
    } else if (cell.isHtml) {
        cellContent = <div className="slds-cell-wrap" dangerouslySetInnerHTML={{ __html: html }} />
    } else {
        cellContent = <div className="slds-cell-wrap">{html}</div>
    }
    // The title should only contain strings, not components
    let title = html
    if (_.isArray(html)) {
        title = _.reduce(
            html,
            (curr, s) => {
                if (typeof s === "string") {
                    return curr + s
                }
                return curr
            },
            ""
        )
    }

    return (
        <DataTableCell {...props} title={title}>
            {cellContent}
        </DataTableCell>
    )
}
TemplateTableCell.displayName = DataTableCell.displayName

const ShowDetailsTableCell = (props) => {
    return (
        <DataTableCell title={"Show Details"} style={{ width: "3.25rem" }} {...props}>
            <div>
                <Button
                    assistiveText={{ icon: "Show Details" }}
                    iconCategory="utility"
                    iconName="open" // preview, record_lookup, open
                    iconVariant="container" // container, border
                    variant="icon"
                    onClick={() => props.showDetails(props.item)}
                />
            </div>
        </DataTableCell>
    )
}
ShowDetailsTableCell.displayName = DataTableCell.displayName

const tableConfigShape = PropTypes.shape({
    cols: PropTypes.arrayOf(
        PropTypes.shape({
            width: PropTypes.string,
            heading: PropTypes.string.isRequired,
            hasFilter: PropTypes.bool,
            cell: PropTypes.shape({
                format: PropTypes.string.isRequired,
                href: PropTypes.string,
                isHtml: PropTypes.bool,
            }),
        })
    ),
})

const MUTATE_CREATE_OR_UPDATE_APP_TABLE_CONFIG = gql`
    mutation deviceTableConfig($appId: ID!, $name: String!, $tableConfig: AppTableConfigInputType!) {
        createOrUpdateAppTableConfig(appId: $appId, name: $name, input: $tableConfig) {
            id
            appId
            name
            config
        }
    }
`

/**
 * Generic data table can handle paging, filtering and sorting using by passing in the results from "usePage", "useFilter", "useSort"
 * A gqlResult can be passed to support reloading of data (refetch)
 * */
export default function GenericDataTable(props) {
    log.Debug("GenericDataTable items:", props.items)
    log.Debug("GenericDataTable.props:", props)
    const t = useT()
    let {
        id,
        items,
        hideDetails,
        fixedLayout,
        children,
        gqlResult,
        // use Loading Spinner while query is loading
        useGqlLoadingSpinner,
        csvExport,
        // Table config
        tableConfigName,
        prefixCols,
        tableConfigDefault,
        handleTableConfigSave,
        // Filtering
        filters,
        search,
        filterField,
        // Events
        page,
        // Sorting
        sort,
        // Selection
        selection,
        reloadEnabled = true,
    } = props

    // if no filter field name is set, fallback to (parsed data) default: "receivedAt". Allows to set date field name for filtering in a timeframe (default was "createdAt")
    if (!filterField) {
        filterField = "receivedAt"
    }

    if (!prefixCols) {
        prefixCols = []
    }

    if (!sort) {
        sort = useSort()
    }

    if (items === null || items === undefined) {
        items = []
    }

    if (page) {
        props.page.setPageItemCount(count(items))
    }

    const canEditTable = !!tableConfigName || handleTableConfigSave

    const app = useContext(AppContext)

    let [settingsOpen, setSettingsOpen] = useState(false)
    let filterTabVisible = filters?.filterTabVisible
    let setFilterTabVisible = filters?.setFilterTabVisible
    let { loading: loadingTableConfig, result: appTableConfigResult, tableConfig } = useAppTableConfig(tableConfigName, tableConfigDefault)
    const [loadingLonger, setLoadingLonger] = useState(false)
    let [mutateTableConfig] = useMutation(MUTATE_CREATE_OR_UPDATE_APP_TABLE_CONFIG)

    useEffect(() => {
        Log.Debug("GenericDataTable - Loading Table Config", gqlResult?.loading)
        let loadingCheck
        if (gqlResult?.loading) {
            loadingCheck = setTimeout(() => {
                Log.Debug("GenericDataTable - Loading longer than 1s")
                setLoadingLonger(true)
            }, 1000)
        } else {
            setLoadingLonger(false)
        }
        return () => {
            clearTimeout(loadingCheck)
        }
    }, [gqlResult])

    Log.Debug("appTableConfigResult:", appTableConfigResult)

    if (loadingTableConfig && loadingLonger) {
        return loadingTableConfig
    }

    Log.Debug("GenericDataTable.tableConfig gql", tableConfig)

    // TODO: This executes an second graphQL query
    // So better execute "useAppTableConfig" outside of this component
    // and set the filter before fetching data
    // Alternatively we could delegate all data loading to the GenericDataTable
    /*
if (tableConfig.defaultFilter) {
Log.Debug("Default Filter:", tableConfig.defaultFilter);
filters.setFilterString(tableConfig.defaultFilterString)
}
*/

    Log.Debug("GenericDataTable.tableConfig", tableConfig)

    // fullTableConfig contains also prefixCols
    let fullTableConfig = { ...tableConfig }
    fullTableConfig.cols = [...prefixCols, ...(tableConfig?.cols || [])]

    const handleSort = (info, event) => {
        const { property } = info

        Log.Debug("handleSort", info)
        let genericColumn = null
        if (property.startsWith("config-col-")) {
            let idx = parseInt(property.replace(/config-col-/, ""))
            genericColumn = fullTableConfig.cols[idx]
            Log.Debug("Sort col:", genericColumn)
            info.property = genericColumn.sortProperty
        }

        if (sort) {
            sort.setSortInfo(info)
        }

        if (gqlResult) {
            // No needed, when useSort is passed from outside
            /*gqlResult.refetch({
sort: sort.getGraphqlSortInput()
});*/
        }

        // on sort go to first page!
        page.setPageInfo({
            offset: 0,
            limit: page.pageInfo.limit,
            total: page.pageInfo.total,
        })

        Log.Debug("Sort handled:", info, genericColumn, event)
    }

    const onCancel = () => {
        setSettingsOpen(false)
    }

    function restoreDefault(formik) {
        if (handleTableConfigSave) {
            return Promise.resolve(handleTableConfigSave(null, formik)).then(() => {
                setSettingsOpen(false)
            })
        }

        return mutateTableConfig({
            variables: {
                appId: app.id,
                name: tableConfigName,
                tableConfig: {
                    config: JSON.stringify(null),
                },
            },
        })
            .then(() => {
                setSettingsOpen(false)
            })
            .catch((err) => {
                Log.Error("Failed to save AppTableConfig", err)
                alert(err.message)
            })
    }

    let loading = undefined
    if (gqlResult && useGqlLoadingSpinner && loadingLonger) {
        loading = useGraphqlLoadingComponent(gqlResult)
    }

    return (
        <>
            <ErrorBoundary>
                <Formik
                    initialValues={tableConfig || {}}
                    onSubmit={(values, actions) => {
                        if (handleTableConfigSave) {
                            return Promise.resolve(handleTableConfigSave(values, actions)).then(() => {
                                setSettingsOpen(false)
                            })
                        }

                        return mutateTableConfig({
                            variables: {
                                appId: app.id,
                                name: tableConfigName,
                                tableConfig: {
                                    config: JSON.stringify(values),
                                },
                            },
                        })
                            .then(() => {
                                setSettingsOpen(false)
                            })
                            .catch((err) => {
                                Log.Error("Failed to save AppTableConfig", err)
                                alert(err.message)
                            })
                            .finally(() => {
                                actions.setSubmitting(false)
                            })
                    }}>
                    {(formik) => {
                        return (
                            <Modal
                                isOpen={settingsOpen}
                                dismissOnClickOutside={false}
                                onRequestClose={() => setSettingsOpen(false)}
                                heading={t("generic-data-table.settings.edit-columns", "Edit Columns")}
                                footer={
                                    <FormActions>
                                        <SubmitButtonField formId={"generic-table-config-form"}>{t("common.button.save", "Save")}</SubmitButtonField>
                                        <CancelButtonField onClick={onCancel} />
                                    </FormActions>
                                }>
                                <GenericDataTableConfigForm
                                    id={"generic-table-config-form"}
                                    tableConfig={tableConfig}
                                    formik={formik}
                                    onCancel={onCancel}
                                    onRestoreDefault={() => restoreDefault(formik)}
                                />
                            </Modal>
                        )
                    }}
                </Formik>
            </ErrorBoundary>
            <ErrorBoundary>
                <div className="slds-col slds-grid slds-grid--vertical">
                    {filterTabVisible /* Render filters when we can apply them */ ? (
                        <FilterPanel size="full" align="center" filters={filters} fieldName={filterField} showQuickAction={true} pagination={page} />
                    ) : null}
                    <div className="slds-grid slds-p-bottom--xx-small slds-p-horizontal--xx-small slds-size_1-of-1">
                        {/*toolbar above table*/}
                        {page ? (
                            <div className="slds-col">
                                <Pagination page={page} />
                            </div>
                        ) : null}
                        {count(props.selection?.selections) ? (
                            <div className="slds-text-title_bold slds-p-right--small">
                                {t("generic-data-table.items-selected", "{{count}} items selected", { count: count(props.selection?.selections) })}
                                <button onClick={(e) => selection.handleSelectionEvent(e, { selection: [] })} className="slds-button">
                                    {t("common.button.clear", "Clear")}
                                </button>
                            </div>
                        ) : null}
                        {search && (
                            <div className="slds-col">
                                <div className="slds-input-has-icon slds-input-has-icon_right">
                                    <input className="slds-input" value={search.keyword} onChange={(event) => search.setKeyword(event.target.value)} />
                                    <span className="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
                                        <svg className="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                            <use href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="slds-col_bump-left slds-float_right">
                            <ButtonGroup className="slds-p-bottom--x-small slds-p-horizontal--small">
                                {gqlResult && reloadEnabled && (
                                    <Button iconCategory={"utility"} iconName={"refresh"} iconVariant={"border"} onClick={() => gqlResult.refetch()} />
                                )}
                                <HasPermission role={"admin"} hidden={!canEditTable}>
                                    <Button iconCategory={"utility"} iconName={"settings"} iconVariant={"border"} onClick={() => setSettingsOpen(true)} />
                                </HasPermission>
                                <Fragment>
                                    {!csvExport?.isProcessing && (
                                        <Button
                                            iconCategory={"utility"}
                                            iconName={"download"}
                                            iconVariant={"border"}
                                            onClick={() => exportCsv(fullTableConfig, items, csvExport)}
                                        />
                                    )}
                                    {csvExport?.isProcessing && (
                                        <Button>
                                            <div className="slds-spinner_container">
                                                <div role="status" className="slds-spinner slds-spinner_x-small">
                                                    <span className="slds-assistive-text">Loading</span>
                                                    <div className="slds-spinner__dot-a"></div>
                                                    <div className="slds-spinner__dot-b"></div>
                                                </div>
                                            </div>
                                        </Button>
                                    )}
                                    {tableConfig?.hasFilter ? (
                                        <Button
                                            iconCategory={"utility"}
                                            iconName={"filterList"}
                                            iconVariant={filterTabVisible ? "brand" : "border"}
                                            onClick={() => setFilterTabVisible(!filterTabVisible)}
                                        />
                                    ) : null}
                                </Fragment>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="slds-col lob-col-overflow">
                        {loading ? (
                            loading
                        ) : (
                            <DataTable
                                /* without fixedLayout the table might get horizontal scrolling
                                 * with fixedLayout all data can be seen, even if it does not fit on the screen (that's more important)
                                 * fixedLayout is needed for sortable tables
                                 * */
                                fixedLayout={fixedLayout}
                                items={items} // Or use items? Or tableItems
                                id={id}
                                onSort={handleSort}
                                selection={selection ? selection.selections : null}
                                onRowChange={selection ? selection.handleSelectionEvent : null}
                                selectRows={!!selection}
                                striped
                                detailsComponent={
                                    hideDetails
                                        ? undefined
                                        : (rowItem) => {
                                              if (props.renderDetails) {
                                                  return <ErrorBoundary>{props.renderDetails(rowItem)}</ErrorBoundary>
                                              }
                                              // This is a good default
                                              return (
                                                  <div>
                                                      <Json json={rowItem} />
                                                  </div>
                                              )
                                          }
                                }>
                                {children}
                                {fullTableConfig.cols.map((c, i) => {
                                    const property = `config-col-${i}`
                                    const isSorted = sort.sortInfo.property === c.sortProperty
                                    const sortable = !!c.sortProperty
                                    let filter = null
                                    if (c.filter?.hasFilter) {
                                        if (c.filter.type == "string") {
                                            //filter = <input type={"text"} value={c.heading}/>
                                            filter = <ColumnTextFilter key={c.filter.property} property={c.filter.property} filterHook={filters} />
                                        } else if (c.filter.type == "boolean") {
                                            filter = <ColumnBooleanFilter key={c.filter.property} property={c.filter.property} filterHook={filters} />
                                        } else if (c.filter.type == "list") {
                                            filter = (
                                                <ColumnTextFilter
                                                    key={c.filter.property}
                                                    arraySearch={true}
                                                    property={c.filter.property}
                                                    filterHook={filters}
                                                />
                                            )
                                        }
                                    }
                                    //if no cell value configured skip column
                                    if (c?.cell?.format) {
                                        return (
                                            <DataTableColumn
                                                key={`${i}`}
                                                label={c.heading}
                                                property={property}
                                                sortable={sortable}
                                                isSorted={isSorted}
                                                filterElem={filter}
                                                sortDirection={sort.sortInfo.direction}>
                                                <TemplateTableCell width={c.width} column={c} />
                                            </DataTableColumn>
                                        )
                                    } else return null
                                })}
                            </DataTable>
                        )}
                    </div>
                    {page ? (
                        <div className="slds-col slds-p-top--x-small slds-p-horizontal--xx-small slds-m-bottom--xx-small">
                            <Pagination page={page} />
                        </div>
                    ) : null}
                </div>
            </ErrorBoundary>
        </>
    )
}

GenericDataTable.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.array, // Empty table is allowed
    selectedItem: PropTypes.any,
    onSelectItem: PropTypes.func,

    tableConfigDefault: tableConfigShape, // The default table config when tableConfigName is used. - DEPRECATED
    tableConfigName: PropTypes.string, // if set, the table config is loaded from the "app_table_config" DB table. - DEPRECATED
    handleTableConfigSave: PropTypes.func, // if set, the table config can be edited and needs to be saved by the handle function
    prefixCols: PropTypes.array, // List of columns to prefix before tableConfig

    renderDetails: PropTypes.func, // Render a detail component that is shown when a line is expanded
    hideDetails: PropTypes.bool, // Hide the details even if renderDetails is set

    fixedLayout: PropTypes.bool,

    // Pass result of "useFilter" hook to support filtering
    filters: PropTypes.object,
    // Pass result of "useSearch" hook to support search
    search: PropTypes.object,
    // Pass result of "useSort" hook to support sorting
    sort: PropTypes.object,
    // Pass result of "usePagination" hook to support pagination
    page: PropTypes.object,

    gqlResult: PropTypes.object, // Allow to handover a GraphQL result to handle sorting. TODO: could be moved to useSort Hook maybe?
    useGqlLoadingSpinner: PropTypes.bool, // use Loading Spinner on gqlResult if still loading
    csvExport: PropTypes.object, // Optional useCsvExport hook. If not present, the items are used for the export

    children: PropTypes.node,
}
