import React, { useEffect, useState } from "react"
import { EditIconButton, errorFromForm, getFormStatusProps, SldsFormElement, SldsFormElementControl, SldsFormElementLabel, startEdit } from "./formElements"
import { DatePicker, Space } from "antd";
import en from "antd/es/date-picker/locale/en_US"
import de from "antd/es/date-picker/locale/de_DE"
import cs from "antd/es/date-picker/locale/cs_CZ"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { Log } from "../../log"
import { Field } from "formik"
import { useT } from "../../i18n"
import i18next from "i18next"

dayjs.extend(localizedFormat)

export const AntDateTimePicker = (props) => {
    return <Field component={AntDateTimePickerImpl} {...props} />
}

const AntDateTimePickerImpl = (props) => {
    const t = useT()
    const { label, form, field, required, showPreset, specialElement } = props
    const id = "input-" + (props.id || field.name)

    let { readOnly, canEdit } = getFormStatusProps(form, props)

    const error = errorFromForm(form, field)

    const [, setRerender] = useState(false)

    useEffect(() => {
        // rerender if t changes
        setRerender((previous) => !previous)
        Log.Debug("AntDateTimePicker.useEffect", "dayjs.locale", dayjs.locale())
    }, [i18next.language])

    const preset = [
        { label: t("time.days", "{{ value }} days", { value: "7" }), value: dayjs().add(7, "d") },
        { label: t("time.days", "{{ value }} days", { value: "14" }), value: dayjs().add(14, "d") },
        { label: t("time.days", "{{ value }} days", { value: "30" }), value: dayjs().add(30, "d") },
        { label: t("time.days", "{{ value }} days", { value: "90" }), value: dayjs().add(90, "d") },
        { label: t("time.year", "{{ value }} year", { value: "1" }), value: dayjs().add(1, "y") },
        { label: t("time.years", "{{ value }} years", { value: "5" }), value: dayjs().add(5, "y") },
        // set to magic number zero to indicate the deletion of the expiresAt field
        { label: t("time.never", "Never"), value: dayjs("0001-01-01T00:00:00Z") },
    ]

    const onChange = (newDate) => {
        Log.Debug("AntDateTimePicker.onOK", "newDate", newDate.toLocaleString(), "form", form)
        form.setFieldValue(field.name, newDate ? newDate.toISOString() : "")
    }

    let locale = en
    if (i18next.language.includes("de")) {
        locale = de
    } else if (i18next.language.includes("cs")) {
        locale = cs
    }

    const dateOrNever = (date) => {
        if (!date) {
            return t("time.never", "Never")
        }
        if (dayjs(date).isSame("0001-01-01T00:00:00Z")) {
            return t("time.never", "Never")
        }
        return dayjs(date).format("lll")
    }

    Log.Debug("AntDateTimePickerImpl", "i18next.language", i18next.language, "locale", locale)

    return (
        <SldsFormElement SldsFormElement error={!!error} canEdit={canEdit} readOnly={readOnly} {...props}>
            {label ? (
                <SldsFormElementLabel htmlFor={id}>
                    {label}
                    {required && !readOnly ? "*" : ""}
                </SldsFormElementLabel>
            ) : null}
            <SldsFormElementControl>
                {readOnly ? (
                    <React.Fragment>
                        <div className="slds-form-element__static" onClick={() => startEdit(form)}>
                            {dateOrNever(field?.value)}
                        </div>
                        {canEdit ? <EditIconButton onClick={() => startEdit(form)} /> : null}
                    </React.Fragment>
                ) : (
                    <>
                        <Space>
                            <DatePicker
                                style={{ width: "200px" }}
                                showWeek
                                showTime={{ format: "HH:mm" }}
                                format="lll"
                                value={field?.value ? dayjs(field.value) : null}
                                presets={showPreset ? preset : null}
                                onOk={onChange}
                                onChange={onChange}
                                allowClear={false}
                                locale={locale}
                            />
                            {specialElement}
                        </Space>
                    </>
                )}
            </SldsFormElementControl>
            {error ? <div className="slds-form-element__help">{error}</div> : null}
        </SldsFormElement>
    )
}
