import {Log} from "../common/log";
import {NotifyUser} from "../common/userNotification";
import {GenericDataTableConfigForm} from "../common/ui/genericDataTable/genericDataTableConfigForm";
import {CancelButtonField, FormActions, SubmitButtonField} from "../common/ui/form/formElements";
import {Formik} from "formik";
import * as React from "react";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "./queries";
import {useGraphqlLoadingComponent} from "../common/graphql";
import {useAuthContext} from "../common/context/authContext";
import {useT} from "../common/i18n";
import {useParams} from "react-router";
import {useContext} from "react";
import {FeatureContext} from "../common/context/featureContext";
import {FeatureNotEnabled} from "../common/featureNotEnabled";

const DeviceTableConfigTab = () => {
    const license = useContext(FeatureContext)
    const t = useT();
    const auth = useAuthContext();
    const deviceTypeId = useParams().id;

    const deviceTypeResult = useQuery(QUERY_DEVICE_TYPE, {
        variables: {
            id: deviceTypeId
        }
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceTypeId},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceTypeId,
            }
        }]
    });


    const loadingHW = useGraphqlLoadingComponent(deviceTypeResult);
    if (loadingHW) {
        return loadingHW;
    }
    const deviceType = deviceTypeResult.data.deviceType;
    const canEdit = auth.hasRole("admin") || (deviceType.private && deviceType.organisationId === auth.organisationId() && auth.hasRole("org-admin"));

    Log.Debug("deviceType", deviceType);
    let initValues = (deviceType.deviceTableConfigRaw && JSON.parse(deviceType.deviceTableConfigRaw)) || {}
    initValues?.cols?.forEach((col, idx, inputArray) => {
        if (col.defaultVisible === "" || col.defaultVisible === undefined || col.defaultVisible === null) {
            inputArray[idx] = {
                ...col,
                defaultVisible: true
            };
        }
    })
    Log.Debug("deviceTableConfigTab.initValues", initValues);

    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <FeatureNotEnabled/>
    }
    return <Formik
        initialValues={initValues}
        enableReinitialize={true}
        initialStatus={{
            readOnly: true,
            canEdit: canEdit
        }}
        onSubmit={(values, actions) => {
            updateDeviceType({
                variables: {
                    id: deviceType.id,
                    input: {
                        deviceTableConfig: JSON.stringify(values),
                    }
                }
            }).catch((err) => {
                NotifyUser.Error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
            }).finally(() => {
                actions.setSubmitting(false);
            });
        }}
        render={formik => {
            return <>
                <GenericDataTableConfigForm
                    id={"generic-table-config-form"}
                    formik={formik}
                    onCancel={() => {
                        Log.Debug("Cancel")
                    }}
                />
                <FormActions>
                    <SubmitButtonField formId={"generic-table-config-form"}/>
                    <CancelButtonField/>
                </FormActions>
            </>;
        }}/>
};

export default DeviceTableConfigTab