import {usePagination} from "../../common/hooks/usePagination";
import {useSearch} from "../../common/hooks/useSearch";
import {useQuery} from "@apollo/client";
import Page from "../../common/ui/page";
import {Link} from "react-router-dom";
import * as React from "react";
import gql from "graphql-tag";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import {useSort} from "../../common/hooks/useSort";
import DataTableCell from "../../common/ui/data-table/cell";
import {useContext} from "react";
import {FeatureContext} from "../../common/context/featureContext";
import {FeatureNotEnabled} from "../../common/featureNotEnabled";
import {useT} from "../../common/i18n";

const sortVbat = {property: 'properties.device"."vbat.value', direction: "asc"}
const QUERY_HARDWARE_BATTERY_LIST = gql`
    query ($page: PaginationInputType, $search: String $sort: SortInputType) {
        devices(page: $page, sort: $sort, search: $search, scope: "global") {
            devices{
                id
                addr
                name
                lastReceived
                description
                app {
                    id
                    name
                }
                organisation {
                    id
                    name
                }
                deviceType {
                    id
                    displayName
                }
                propertiesRaw
                initialConfigRaw
                serial   
            }
        }
    }`;

const HardwareBatteryTable = (props) => {
    const now = new Date()
    return <GenericDataTable id={"hardware-table"} items={props?.result?.data?.devices?.devices || []} tableConfigDefault={{}}
                             gqlResult={props.result}
                             page={props.page} search={props.search}
                             sort={props.sort}>
        <DataTableColumn label="VBat" sortable={true} isSorted={props.sort.sortInfo?.property === "properties.device\".\"vbat.value"} property={'properties.device"."vbat.value'} title={"VBat"}>
            <DataTableCell key="vbat" render={(props) => (
                JSON.parse(props.item.propertiesRaw)?.["device.vbat"]?.value
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Last Seen (Days ago)" sortable={true} property="lastReceived" title={"Last Reveived"}>
            <DataTableCell key="lastReceived" render={(props) => {
                return props?.item?.lastReceived && Math.floor((now.getTime() - Date.parse(props.item.lastReceived))/1000/60/60/24)
            }}/>
        </DataTableColumn>
        <DataTableColumn label="Name" property="name" title={"Name"}>
            <DataTableCell key="id" render={(props) => (
                <Link to={`/configuration/hardware/${props.item.id}`}>{props.item.name}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Address" property="addr" title={"Addr"}>
            <DataTableCell key="id" render={(props) => (
                <Link to={`/configuration/hardware/${props.item.id}`}>{props.item.addr}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Device Type" property={"deviceType"}>
            <DataTableCell key="type" render={(props) => {
              return props?.item?.deviceType?.id && <Link to={`/deviceTypes/${props.item.deviceType.id}`}>{props.item.deviceType.displayName}</Link>
            }}/>
        </DataTableColumn>

        <DataTableColumn label="FWVersion" property="Version App" title={"App Version"}>
            <DataTableCell key="FWVersion" render={(props) => (
             JSON.parse(props.item.propertiesRaw)?.["device.app"]?.value
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Version Boot" property="boot Version" title={"Boot Version"}>
            <DataTableCell key="FWVersion" render={(props) => (
             JSON.parse(props.item.propertiesRaw)?.["device.boot"]?.value
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Organization" property={"Organization"}>
            <DataTableCell key="type" render={(props) => (
                props.item.organisation ? <Link to={`/configuration/organisations/${props.item.organisation.id}`}>{props.item.organisation.name}</Link> : null
            )}/>
        </DataTableColumn>
    </GenericDataTable>
};


export const HardwareBatteryPage = () => {
    const license = useContext(FeatureContext)
    const t = useT()
    const page = usePagination();
    const search = useSearch();
    const sort = useSort(sortVbat)

    const result = useQuery(QUERY_HARDWARE_BATTERY_LIST, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput(),
            sort: sort.getGraphqlSortInput(),
        }
    });

    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <Page trail={[<Link to="." key={1}>{t("config.hardwareBattery.title", "Hardware Battery Status")}</Link>]}
                     title={t("config.hardwareBattery.title", "Hardware Battery Status")}>
            <FeatureNotEnabled/>
        </Page>
    }

    // TODO: Use T for translations
    return <Page
        trail={[<Link to="." key={1}>{t("config.hardwareBattery.title", "Hardware Battery Status")}</Link>]}
        title={t("config.hardwareBattery.title", "Hardware Battery Status")}
    >

        <HardwareBatteryTable result={result} page={page} search={search} sort={sort}/>

    </Page>;
};
