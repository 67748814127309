import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { CancelButtonField, FormActions, SldsInputField, SubmitButtonField } from "../../common/ui/form/formElements"
import gql from "graphql-tag"
import { useGraphqlLoadingComponent } from "../../common/graphql"
import { Formik } from "formik"
import { Form } from "../../common/ui/form/formik"
import { Link, useNavigate } from "react-router-dom"
import Page from "../../common/ui/page"
import * as Yup from "yup"
import SingleLookupField from "../../common/ui/lookup/singleLookupField"
import * as Log from "../../common/log"
import ChangePasswordDialog from "../../auth/changePasswordDialog"
import { useAuthContext } from "../../common/context/authContext"
import { getQueryStringParams } from "../../common/helper"
import { NotifyUser } from "../../common/userNotification"
import Button from "../../common/slds/buttons/button"
import UserRolesField from "../../components/user/UserRolesField"
import ButtonGroup from "../../common/slds/buttonGroup/ButtonGroup"
import { useNotificationContext } from "../../notifications/notificationContext"
import DescriptionList, { DescriptionListEntry } from "../../common/slds/descriptionList/descriptionList"
import Heading from "../../common/slds/text/heading"
import { useParams } from "react-router"
import { AntDateTimePicker } from "../../common/ui/form/antFormElements"
import dayjs from "dayjs"
import { useT } from "../../common/i18n"

const QUERY_USER = gql`
    query ($id: ID!) {
        getUser(id: $id) {
            id
            login
            name
            roles
            organisation {
                id
                name
            }
            baseOrganisation {
                id
                name
            }
            createdAt
            email
            oneTimePassword
            lastLogin
            expiresAt
        }
    }
`

const MUTATION_UPDATE_USER = gql`
    mutation ($id: ID!, $input: UserInput) {
        updateUser(id: $id, input: $input) {
            id
            login
            name
            roles
            organisation {
                id
                name
            }
            createdAt
            email
            oneTimePassword
        }
    }
`

const MUTATION_SEND_ACCOUNT_MAIL = gql`
    mutation ($userId: ID!) {
        mail: sendUserEmailInvitation(userId: $userId) {
            to
            cc
            bcc
            subject
        }
    }
`

const MUTATION_DELETE_USER = gql`
    mutation deleteUser($userId: ID!) {
        deleteUser(id: $userId) {
            id
            name
        }
    }
`

const UserDetailPage = () => {
    const t = useT()
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
    const auth = useAuthContext()
    const navigate = useNavigate()
    const notify = useNotificationContext()
    const userId = useParams().id

    const userDetailResult = useQuery(QUERY_USER, {
        variables: {
            id: userId,
        },
    })

    const [mutateUpdateUser] = useMutation(MUTATION_UPDATE_USER, {
        variables: {
            id: userId,
        },
    })
    const [mutateSendAccountMail] = useMutation(MUTATION_SEND_ACCOUNT_MAIL, {
        variables: {
            userId: userId,
        },
    })

    const [mutationDeleteUser] = useMutation(MUTATION_DELETE_USER, {
        variables: { userId: userId },
    })

    const organisationListResult = useQuery(
        gql`
            query ($search: String) {
                getOrganisationList(search: $search) {
                    id
                    name
                }
            }
        `,
        {
            variables: {
                page: {
                    offset: 0,
                    limit: 10,
                },
            },
        }
    )

    let isAdmin = auth.hasRole("admin")
    let isOrgAdmin = isAdmin || auth.hasRole("org-admin")
    let userIsAdmin = userDetailResult?.data?.getUser?.roles?.includes("admin")
    let userIsOrgAdmin = userDetailResult?.data?.getUser?.roles?.includes("org-admin")

    let allowExpireAt = (isAdmin && !userIsAdmin) || (isOrgAdmin && !userIsAdmin && !userIsOrgAdmin)

    const userDetail = { ...userDetailResult?.data?.getUser }

    function generateOtp() {
        return mutateUpdateUser({
            variables: {
                input: {
                    oneTimePassword: "generate",
                },
            },
        })
            .then(() => {
                notify.info(t("user.otp.ok", "Generated new OTP"))
            })
            .catch((err) => {
                notify.error(t("user.otp.fail", "Failed to generate OTP"), err)
            })
    }

    function sendInvitationMail() {
        let p = Promise.resolve()
        if (!userDetail.oneTimePassword) {
            p = generateOtp()
        }
        p.then(() => {
            mutateSendAccountMail()
                .then((r) => {
                    notify.info(
                        <>
                            <Heading size={"small"}>{t("user.mail.ok", "Successfully sent account mail")}</Heading>
                            <DescriptionList>
                                <DescriptionListEntry label={"To"}>{r.data?.mail?.to}</DescriptionListEntry>
                                <DescriptionListEntry label={"CC"}>{r.data?.mail?.cc}</DescriptionListEntry>
                                <DescriptionListEntry label={"BCC"}>{r.data?.mail?.bcc}</DescriptionListEntry>
                                <DescriptionListEntry label={"Subject"}>{r.data?.mail?.subject}</DescriptionListEntry>
                            </DescriptionList>
                        </>
                    )
                })
                .catch((err) => {
                    notify.error(t("user.mail.failed", "Failed to send account mail"), err)
                })
        })
    }

    function deleteUser() {
        if (
            window.confirm(
                t("user.delete.confirm", "Delete User {{userName}} Permanently?", {
                    userName: userDetail.name,
                })
            )
        ) {
            mutationDeleteUser().then(
                () => {
                    notify.success(t("user.delete.ok", "deleted user"))
                    navigate("..", { relative: "path" })
                },
                (err) => {
                    notify.error(t("user.delete.fail", "Failed to delete user."), err?.toString())
                }
            )
        }
    }

    const expireUser = () => {
        return mutateUpdateUser({
            variables: {
                input: {
                    expiresAt: dayjs().toISOString(),
                },
            },
            refetchQueries: [{ query: QUERY_USER, variables: { id: userId } }],
        })
            .then(() => {
                notify.info(t("user.expire.ok", "User access expired"))
            })
            .catch((err) => {
                notify.error(t("user.expire.fail", "Failed to expire user"), err)
            })
    }

    const loading = useGraphqlLoadingComponent(userDetailResult)
    if (loading) {
        return loading
    }

    const passwordResetUrl = userDetail.oneTimePassword && `${window.location.origin}/#/auth/changePassword/${userDetail.id}?otp=${userDetail.oneTimePassword}`

    return (
        <Page
            trail={[
                <Link to="../users" key={1}>
                    Users
                </Link>,
                <Link to={"./"} key={2}>
                    {userDetail.login}
                </Link>,
            ]}
            title={`${userDetail.name} (${userDetail.login})`}
            actions={
                <ButtonGroup>
                    {isAdmin ? (
                        <Button
                            iconName={"resource_territory"}
                            onClick={() => {
                                auth.impersonate(userDetail.login)
                                    .then(() => {
                                        const queryStringParams = getQueryStringParams(location.search)
                                        const redirectTo = queryStringParams.r || "/"
                                        navigate(redirectTo)
                                    })
                                    .catch((err) => {
                                        NotifyUser.Error(t("user.impersonate.fail", "Failed to impersonate user"), err)
                                    })
                            }}>
                            {t("user.impersonate.label", "Impersonate")}
                        </Button>
                    ) : null}
                    {isAdmin ? (
                        <Button
                            iconCategory={"utility"}
                            iconName={"delete"}
                            onClick={() => {
                                deleteUser()
                            }}>
                            {t("user.delete.label", "Delete")}
                        </Button>
                    ) : null}
                </ButtonGroup>
            }
            withPadding={true}>
            <Formik
                initialValues={{
                    ...userDetail,
                }}
                onSubmit={(values, actions) => {
                    Log.Debug("UserDetailPage.submit", values)
                    return mutateUpdateUser({
                        variables: {
                            input: {
                                name: values.name,
                                login: values.login,
                                email: values.email,
                                organisationId: values.organisation.id,
                                baseOrganisationId: values.baseOrganisation.id,
                                roles: values.roles,
                                expiresAt: values.expiresAt,
                            },
                        },
                        refetchQueries: [{ query: QUERY_USER, variables: { id: userId } }],
                    }).catch((err) => {
                        Log.Error(t("user.update.failed", "Failed to update user"), err)
                        actions.setFieldError("global", t("user.update.failed", "Failed to update user"))
                    })
                }}
                initialStatus={{
                    readOnly: true,
                    canEdit: true,
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().strict().shape({
                    name: Yup.string().required().trim(),
                })}>
                <Form>
                    <SldsInputField readOnly={true} canEdit={false} name={"id"} label={"ID"} />
                    <SldsInputField name={"login"} label={t("user.login", "Login")} />
                    <SldsInputField name={"name"} label={t("user.name", "Name")} />
                    <SldsInputField name={"email"} label={t("user.email", "E-Mail")} />
                    <UserRolesField name={"roles"} label={t("user.roles", "Roles")} />
                    <SingleLookupField
                        name={"organisation"}
                        label={t("user.organisation", "Organisation")}
                        loadSuggestions={(keyword) => organisationListResult.refetch({ search: keyword }).then((result) => result.data.getOrganisationList)}
                        titleExtractor={(it) => it.name}
                        subtitleExtractor={(it) => it.id}
                    />
                    <SingleLookupField
                        name={"baseOrganisation"}
                        label={t("user.base-organisation", "Base Organisation")}
                        loadSuggestions={(keyword) => organisationListResult.refetch({ search: keyword }).then((result) => result.data.getOrganisationList)}
                        titleExtractor={(it) => it.name}
                        subtitleExtractor={(it) => it.id}
                    />
                    <SldsInputField readOnly={true} canEdit={false} name={"lastLogin"} label={t("user.login-last", "Last Login")} />
                    {allowExpireAt && (
                        <AntDateTimePicker
                            submitFormOnChange={false}
                            label={t("user.expire.label", "Expires At")}
                            name="expiresAt"
                            showPreset={true}
                            specialElement={
                                <Button iconName="clear" onClick={() => expireUser()}>
                                    {t("user.expire.now", "Expire User Access Now")}
                                </Button>
                            }
                        />
                    )}
                    {!allowExpireAt && (
                        <AntDateTimePicker
                            submitFormOnChange={false}
                            readOnly={true}
                            canEdit={false}
                            label={t("user.expire.label", "Expires At")}
                            name="expiresAt"
                            showPreset={true}
                        />
                    )}
                    <FormActions>
                        <SubmitButtonField />
                        <CancelButtonField />
                    </FormActions>
                </Form>
            </Formik>

            <div className="slds-p-top--small">
                {isOrgAdmin ? (
                    <div>
                        <ChangePasswordDialog
                            userId={userDetail.id}
                            isOpen={showChangePasswordModal}
                            onRequestClose={() => setShowChangePasswordModal(false)}
                        />
                        <Button onClick={() => setShowChangePasswordModal(true)}>{t("user.password.change", "Change Password")}</Button>
                    </div>
                ) : null}
                <Button iconName="email" onClick={() => sendInvitationMail()}>
                    {t("user.invitation.send", "Send Invitation Mail")}
                </Button>
                {isOrgAdmin ? (
                    <div>
                        {passwordResetUrl ? (
                            <a href={passwordResetUrl} target="_blank">
                                {passwordResetUrl}
                            </a>
                        ) : (
                            <Button iconName={"password"} onClick={generateOtp}>
                                {t("user.password.reset", "Create Password Reset Link")}
                            </Button>
                        )}
                    </div>
                ) : null}
            </div>
        </Page>
    )
}

export default UserDetailPage
